import React, {useContext, useEffect} from 'react';
import {Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Tooltip} from "antd";
import {removeCurrentFormItem} from "@/utils/tool";
import UploadFileBox from "@/components/common/dragUpload";
import {moveItem, StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import {PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import Title from "antd/es/typography/Title";
import {setBooks, setFundingAwards, setPatents} from "@/store/modules/quick";

const {Option} = Select;

const BookPatentFunding = () => {

    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickFormData = useSelector(state => state.quickForm.quickFilingForm);

    let addBooks;
    let addPatent;
    let addFundingAward;

    useEffect(() => {
        form.setFieldsValue({
            books: quickFormData.books || [],
            patents: quickFormData.patents || [],
            fundingAwards: quickFormData.fundingAwards || [],
        });
    }, [])

    const onFinishBookPatentFunding = async () => {
        // 跳转到下一步
        await setForm2Redux()
        setCurrent(current + 1);
    }

    const prev = async () => {
        await setForm2Redux()
        setCurrent(current - 1);
    }

    const setForm2Redux = async () => {
        try {
            // 验证表单数据
            const values = await form.validateFields();
            // 验证表单数据
            const updatedBooks = [
                // ...quickFormData.books,
                ...values.books,
            ];
            dispatch(setBooks(updatedBooks));
            const updatedPatents = [
                // ...quickFormData.patents,
                ...values.patents,
            ];
            dispatch(setPatents(updatedPatents));
            const updatedFunding = [
                // ...quickFormData.funding,
                ...values.fundingAwards,
            ];
            dispatch(setFundingAwards(updatedFunding));
        } catch (error) {
            console.error('Validation Failed:', error);
        }
    }

    return (

        <div className={'eduEmployForm'}>
            <Form form={form} layout="vertical">
                <div>
                    <Title level={3}>Books & Patents & Funding</Title>
                </div>
                <Divider/>
                <Title level={4}>Books</Title>
                <br/>
                <Form.List name="books">
                    {(fields, {add, remove}) => {
                        addBooks = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("books", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Books-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'name']}
                                                                    label='Book Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'publisher']}
                                                                    label='Publisher'
                                                                >
                                                                    <Input.TextArea rows={2}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'brief']}
                                                                    label='Brief Intro'
                                                                >
                                                                    <Input.TextArea rows={6}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'books'} index={index}
                                                                   label={"book_proof"}/>
                                                    <br/>
                                                    <Title level={4}>The Impact of Your Book</Title>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'impact']}
                                                                    label='Impact'
                                                                >
                                                                    <Input.TextArea rows={6}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'books'} index={index}
                                                                   label={"impact_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'books')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'books')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addBooks()}
                            icon={<PlusOutlined/>}
                        >
                            Add Books
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Patents</Title>
                <br/>
                <Form.List name="patents">
                    {(fields, {add, remove}) => {
                        addPatent = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("patents", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Patent-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'number']}
                                                                    label='Number'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'title']}
                                                                    label='Title'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'intro']}
                                                                    label='Short Intro'
                                                                >
                                                                    <Input.TextArea rows={6}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'status']}
                                                                    label='Status'
                                                                >
                                                                    <Select>
                                                                        <Option value="0">Provisional</Option>
                                                                        <Option value="1">Issued</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'patents'} index={index}
                                                                   label={"patent_proof"}/>
                                                    <br/>
                                                    <Row gutter={16}>
                                                        <Col span={24}>
                                                            <Form.Item name={[name, 'licenseStatus']}
                                                                       valuePropName="unchecked">
                                                                <Checkbox style={{fontSize: 15, fontWeight: "bolder"}}>Licensing
                                                                    Status</Checkbox>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[name, 'licensee']}
                                                                    label='Licensee'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[name, 'licensor']}
                                                                    label='Licensor'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[name, 'summary']}
                                                                    label={<Tooltip
                                                                        title={'If licensed for commercial use'}>Summary
                                                                        Of Licensed Application</Tooltip>}
                                                                >
                                                                    <Input.TextArea rows={3}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>License Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'patents'} index={index}
                                                                   label={"license_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'patents')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'patents')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addPatent()}
                            icon={<PlusOutlined/>}
                        >
                            Add Patent
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Funding Awards</Title>
                <br/>
                <Form.List name="fundingAwards">
                    {(fields, {add, remove}) => {
                        addFundingAward = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("fundingAwards", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Funding Award-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'title']}
                                                                    label='Title'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'agencies']}
                                                                    label={<Tooltip title={'e.g.: NSF, DHS, etc.'}>Funding
                                                                        Agencies</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'role']}
                                                                    label={<Tooltip
                                                                        title={'PI, co-PI, or senior Personnel'}>Role</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'amount']}
                                                                    label={<Tooltip title={'USD'}>Amount</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'intro']}
                                                                    label='Brief Intro'
                                                                >
                                                                    <Input.TextArea rows={3}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'awardDate']}
                                                                    label='Award Date'
                                                                >
                                                                    <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'fundingAwards'} index={index}
                                                                   label={'award_proof'}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'fundingAwards')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'fundingAwards')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addFundingAward()}
                            icon={<PlusOutlined/>}
                        >
                            Add Funding Award
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
            </Form>
            <Button style={{margin: '0 8px'}} onClick={prev}>Prev</Button>
            <Button type={"primary"} onClick={onFinishBookPatentFunding}>Next</Button>
        </div>

    )
}

export default BookPatentFunding