import { createSlice } from '@reduxjs/toolkit';

const quickStore = createSlice({
    name: "quickForm",
    initialState: {
        sideProfile: null,
        quickFilingForm: {
            intro : {},
            educations: [],
            employments: [],
            research : {},
            unlistedArticles:[],
            missingCitations:[],
            highlightComments:[],
            books : [],
            patents: [],
            fundingAwards: [],
            honors: [],
            mediaReports : [],
            presentations: [],
            reviewerExperiences: [],
            professionalMemberships: [],
            awardFundingReviewerExperiences: [],
            consultingExperiences: [],
            openSourceProjects:[],
            workUsedByIndustry:[],
            requestsFor:[],
            workUsedForCourses:[],
        }
    },
    reducers: {
        setSideProfile(state, action) {
            state.sideProfile = action.payload;
        },
        setQuickFilingForm(state, action) {
            state.quickFilingForm = action.payload;
        },
        setField: (state, action) => {
            const { fieldName, value } = action.payload;
            state.quickFilingForm[fieldName] = value;
        },
        setIntro: (state, action) => {
            state.quickFilingForm.intro = action.payload;
        },
        setEducations: (state, action) => {
            state.quickFilingForm.educations = action.payload;
        },
        setEmployments: (state, action) => {
            state.quickFilingForm.employments = action.payload;
        },
        setResearch: (state, action) => {
            state.quickFilingForm.research = action.payload;
        },
        setUnlistedArticles: (state, action) => {
            state.quickFilingForm.unlistedArticles = action.payload;
        },
        setMissingCitations: (state, action) => {
            state.quickFilingForm.missingCitations = action.payload;
        },
        setHighlightComments: (state, action) => {
            state.quickFilingForm.highlightComments = action.payload;
        },
        setBooks: (state, action) => {
            state.quickFilingForm.books = action.payload;
        },
        setPatents: (state, action) => {
            state.quickFilingForm.patents = action.payload;
        },
        setFundingAwards: (state, action) => {
            state.quickFilingForm.fundingAwards = action.payload;
        },
        setHonors: (state, action) => {
            state.quickFilingForm.honors = action.payload;
        },
        setMediaReports: (state, action) => {
            state.quickFilingForm.mediaReports = action.payload;
        },
        setPresentations: (state, action) => {
            state.quickFilingForm.presentations = action.payload;
        },
        setReviewerExperiences: (state, action) => {
            state.quickFilingForm.reviewerExperiences = action.payload;
        },
        setProfessionalMemberships: (state, action) => {
            state.quickFilingForm.professionalMemberships = action.payload;
        },
        setAwardFundingReviewerExperiences: (state, action) => {
            state.quickFilingForm.awardFundingReviewerExperiences = action.payload;
        },
        setConsultingExperiences: (state, action) => {
            state.quickFilingForm.consultingExperiences = action.payload;
        },
        setOpenSourceProjects: (state, action) => {
            state.quickFilingForm.openSourceProjects = action.payload;
        },
        setWorkUsedByIndustry: (state, action) => {
            state.quickFilingForm.workUsedByIndustry = action.payload;
        },
        setRequestsFor: (state, action) => {
            state.quickFilingForm.requestsFor = action.payload;
        },
        setWorkUsedForCourses: (state, action) => {
            state.quickFilingForm.workUsedForCourses = action.payload;
        },
    },
});

export const {
    setQuickFilingForm,
    setSideProfile,
    setField,
    setIntro,
    setEducations,
    setEmployments,
    setResearch,
    setUnlistedArticles,
    setMissingCitations,
    setHighlightComments,
    setBooks,
    setPatents,
    setFundingAwards,
    setHonors,
    setMediaReports,
    setPresentations,
    setReviewerExperiences,
    setProfessionalMemberships,
    setAwardFundingReviewerExperiences,
    setConsultingExperiences,
    setOpenSourceProjects,
    setWorkUsedByIndustry,
    setRequestsFor,
    setWorkUsedForCourses,
} = quickStore.actions;

const quickReducer = quickStore.reducer;
export default quickReducer
