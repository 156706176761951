import React from 'react';
import Title from "antd/es/typography/Title";
import {Button, Divider, Space, Table} from "antd";



const columnsForPublication = [
    {
        title: 'Your publication',
        dataIndex: 'title',
        key: 'title',
        render: (text) => <a>{text} </a>,
        className: 'word-break',
        width: '300px'
    },

    {
        title: 'Resource Link',
        dataIndex: 'link_url',
        key: 'link_url',
        render: (text) => <a>{text} </a>,
        className: 'word-break',
        width: '300px'
    },
    {
        title: 'Check ',
        dataIndex: 'preview_link_url',
        key: 'preview_link_url',
        render: (text) => <Button type={'default'} href={text}>view</Button>,
        width: 60
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
            <Space size="middle">
                <Button danger >Ignore</Button>
                <Button type={"default"} >Confirm</Button>
                <Button type={"primary"} >Update</Button>
            </Space>
        ),
        width : 200
    },
];


const columnsForCitation = [
    {
        title: 'Your publication',
        dataIndex: 'title',
        key: 'title',
        render: (text) => <a>{text} </a>,
        className: 'word-break',
        width: '300px'
    },
    {
        title: 'Citation',
        dataIndex: 'citation',
        key: 'citation',
        render: (text) => <a>{text} </a>,
        className: 'word-break',
        width: '300px'
    },

    {
        title: 'Resource Link',
        dataIndex: 'link_url',
        key: 'link_url',
        render: (text) => <a>{text} </a>,
        className: 'word-break',
        width: '200px'
    },
    {
        title: 'Check ',
        dataIndex: 'preview_link_url',
        key: 'preview_link_url',
        render: (text) => <Button type={'default'} href={text}>view</Button>,
        width: 60
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
            <Space size="middle">
                <Button danger >Ignore</Button>
                <Button type={"default"} >Confirm</Button>
                <Button type={"primary"} >Update</Button>
            </Space>
        ),
        width : 200
    },


]



const dataForPublication = [
    {
        key: '1',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '2',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '3',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '4',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
];

const dataForCitation = [
    {
        key: '1',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '2',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '3',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '4',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        preview_link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
];




const CheckMissingFiles = () => {


    return (
        <div>
            <div>
                <Title level={4}>Check downloaded files for your publication({dataForPublication.length})</Title>
                <br/>
                <Table columns={columnsForPublication}
                       dataSource={dataForPublication}
                       pagination={false}
                       scroll={{
                           y: 400,
                       }}
                />
            </div>

            <br />
            <Divider />
            <br />

            <div>
                <Title level={4}>Check downloaded files for your citation({dataForCitation.length})</Title>
                <br/>
                <Table columns={columnsForCitation}
                       showHeader={true}
                       dataSource={dataForCitation}
                       pagination={false}
                       scroll={{
                           y: 400,
                       }}
                />
            </div>


        </div>
    )
}


export default CheckMissingFiles;