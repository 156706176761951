import {Link} from "react-router-dom";
import {Button, Col, Flex, Row, Space, Typography} from "antd";
import { CheckCircleFilled} from "@ant-design/icons";


const {Title} = Typography;

const EB1AKit = () => {


    return (

        <div className="plan-item">
            <div className={'plan-item-title'}>
                <h2>EB1AKit</h2>
            </div>
            <div className={'plan-item-link'}>
                <div>
                    <Link to={'#'}>How to edit your petition package?</Link>
                </div>
            </div>

            <div className="plan-item-content">
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div className={'plan-item-feature'}>
                            <Title level={4}>Features</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Everything included in SoCKit</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Generate entire petition package (instructions, cover
                                letter, I-140, etc.)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Approved EB1A petition letter examples</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Generate EB1A Petition Letter (8 times, $60/Gen after
                                that, max $180)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Generate summary of contribution (5 times, $60/Gen
                                after that, max $180)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Assemble all docs into one ready-to-print-and-file PDF
                                (5 times, $60/Gen after that, max $180)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Analyze Google Scholar page (&lt;500 citations,
                                $1/citation after that, max $300)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Document Translation by AI ($1 per page, $50 minimum)</Title>
                        </div>
                    </Col>

                    <Col span={7}>
                        <Title level={4}>Pricing</Title>
                        <br/>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>SoCKit</Title></div>
                            <div><Title level={5}>$949 / Month</Title></div>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>Add on - 1807 citations</Title></div>
                            <div><Title level={5}>$300 / Month</Title></div>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>Subtotal</Title></div>
                            <div><Title level={5}>$1249 / Month</Title></div>
                        </Flex>
                    </Col>
                    <Col span={9}>
                        <Flex className={'imigraBoost-content-button'} justify={'center'} align={'center'}>
                            <div>
                                <Space direction={'vertical'}>
                                    <Button style={{width: '100%'}} type={"primary"}>Pay</Button>
                                    <Button style={{width: '100%'}} type={"primary"}>Generate EB1AKit</Button>
                                    <Link to={'#'}>How to Gen Demo</Link>
                                </Space>
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default EB1AKit