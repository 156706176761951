import {Link} from "react-router-dom";
import {Button, Col, Flex, Row, Space, Typography} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";




const {Title} = Typography;


const SOCKit = () => {


    return (
        <div className="plan-item">
            <div className={'plan-item-title'}>
                <h2>SoCKit</h2>
            </div>
            <div className={'plan-item-link'}>
                <div>
                    <Link  to={'#'}>Get Started - SoCKit</Link>
                </div>
                <br/>
                <div>
                    <Link to={'#'}>How to edit your petition package?</Link>
                </div>
            </div>
            <div className="plan-item-content">
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div className={'plan-item-feature'}>
                            <Title level={4}>Features</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Smart achievement collection template</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />AI-powered evidence generation</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Editable and sharable DropBox folder</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Analyze Google Scholar page (&lt;200 citations, $1/citation after that, max $300)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Generate summary of contribution (5 times, $60/Gen after that, max $180)</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Doument Translation by AI ($1 per page, $50 minimum)</Title>
                        </div>
                    </Col>

                    <Col span={8}>
                        <Title level={4}>Pricing</Title>
                        <br/>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>SoCKit</Title></div>
                            <div><Title level={5}>$299 / Month</Title></div>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>Add on - 1807 citations</Title></div>
                            <div><Title level={5}>$300 / Month</Title></div>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>Subtotal</Title></div>
                            <div><Title level={5}>$599 / Month</Title></div>
                        </Flex>
                    </Col>
                    <Col span={7}>
                        <Flex className={'imigraBoost-content-button'} justify={'center'} align={'center'}>
                            <div>
                                <Space direction={'vertical'}>
                                    <Button style={{width: '100%'}} type={"primary"}>Pay</Button>
                                    <Button style={{width: '100%'}} type={"primary"}>Demo</Button>
                                    <Link to={'#'}>How to Gen Demo</Link>
                                </Space>
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SOCKit