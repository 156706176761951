import {Button, Checkbox, Col, Divider, Form, Input, Row, Space, Tooltip} from "antd";
import {removeCurrentFormItem} from "@/utils/tool";
import {moveItem, StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import {PlusOutlined} from "@ant-design/icons";
import UploadFileBox from "@/components/common/dragUpload";
import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setOpenSourceProjects,
    setRequestsFor,
    setWorkUsedByIndustry,
    setSideProfile
} from "@/store/modules/quick";
import Title from "antd/es/typography/Title";
import {submitProfile} from "@/apis/quick";
import store from "@/store";
import {useNavigate} from "react-router-dom";


const IndustryAdoption = () => {

    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickFormData = useSelector(state => state.quickForm.quickFilingForm);
    const navigate = useNavigate();

    useEffect(() => {
        form.setFieldsValue({
            openSourceProjects: quickFormData.openSourceProjects || [],
            workUsedByIndustry: quickFormData.workUsedByIndustry || [],
            requestsFor: quickFormData.requestsFor|| [],
        });
    }, [])

    const onSubmit = async () => {
        await setForm2Redux()
        // submit
        const updatedQuickForm = store.getState().quickForm.quickFilingForm;
        submitProfile(updatedQuickForm).then(
            res => {
                console.log("submitProfile---", res);
                if (res.code === 0) {
                    dispatch(setSideProfile(res.data))
                    navigate("/quickfiling")
                }
            }
        ).catch(
            err => console.log(err)
        )
    }

    const prev = async () => {
        await setForm2Redux()
        setCurrent(current - 1);
    }


    const setForm2Redux = async () => {
        try {
            // 验证表单数据
            const values = await form.validateFields();
            // 保存到 Redux
            const updateOpenSourceProjects = [
                // ...quickFormData.openSourceProjects,
                ...values.openSourceProjects,
            ]
            dispatch(setOpenSourceProjects(updateOpenSourceProjects));
            const updateWorkUsedByIndustry = [
                // ...quickFormData.workUsedByIndustry,
                ...values.workUsedByIndustry,
            ]
            dispatch(setWorkUsedByIndustry(updateWorkUsedByIndustry));
            const updateRequestsFor = [
                // ...quickFormData.requestsFor,
                ...values.requestsFor,
            ]
            dispatch(setRequestsFor(updateRequestsFor));
        } catch (error) {
            console.error('Validation Failed:', error);
        }
    }


    let addOpenSourceProjects;
    let addWorkUsedByIndustry;
    let addRequestsForDataCodeOrPapers;

    return (
        <div className={'eduEmployForm'}>
            <Form form={form} layout={"vertical"}>
                <div>
                    <Title level={3}>OpenSource & IndustryWork & RequestsFor</Title>
                </div>
                <Divider/>
                <Title level={4}>Open Source Projects</Title>
                <Form.List name="openSourceProjects">
                    {(fields, {add, remove}) => {
                        addOpenSourceProjects = add;
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("openSourceProjects", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => (
                                    <div className={'edu-history'} key={index}>
                                        <Title level={5}>*open source projects-{index + 1}</Title>
                                        <Space align={'start'}>
                                            <div>
                                                <div className={'edu-history-item'}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'name']}
                                                                label='Name'
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'intro']}
                                                                label='Brief Intro'
                                                            >
                                                                <Input.TextArea rows={4}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'github']}
                                                                label='Github Link'
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'usedByOther']}
                                                                valuePropName="checked"
                                                            >
                                                                <Checkbox>Used By Other Projects</Checkbox>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Title level={5}>Proof</Title>
                                                <UploadFileBox form={form} fieldName={'openSourceProjects'}
                                                               index={index} label={'openSource_industry_proof'}/>
                                            </div>
                                            <Space direction={"vertical"}>
                                                {fields.length > 1 && <Space direction={"vertical"}>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, -1, fields, 'openSourceProjects')}>Up</Button>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, 1, fields, 'openSourceProjects')}>Down</Button>
                                                </Space>}
                                                <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                            </Space>
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addOpenSourceProjects()}
                            icon={<PlusOutlined/>}
                        >
                            Add Open Source Projects
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Work Used By Industry</Title>
                <Form.List name="workUsedByIndustry">
                    {(fields, {add, remove}) => {
                        addWorkUsedByIndustry = add;
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("workUsedByIndustry", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => (
                                    <div className={'edu-history'} key={index}>
                                        <Title level={5}>*work used by industry-{index + 1}</Title>
                                        <Space align={'start'}>
                                            <div>
                                                <div className={'edu-history-item'}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'companyName']}
                                                                label='Company Name'
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'useCase']}
                                                                label='Use Case'
                                                            >
                                                                <Input.TextArea rows={4}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'impact']}
                                                                label='Impact'
                                                            >
                                                                <Input.TextArea rows={4}/>
                                                            </Form.Item>
                                                        </Col>

                                                    </Row>
                                                </div>
                                                <Title level={5}>Proof</Title>
                                                <UploadFileBox form={form} fieldName={'workUsedByIndustry'}
                                                               index={index} label={'industry_proof'}/>
                                            </div>
                                            <Space direction={"vertical"}>
                                                {fields.length > 1 && <Space direction={"vertical"}>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, -1, fields, 'workUsedByIndustry')}>Up</Button>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, 1, fields, 'workUsedByIndustry')}>Down</Button>
                                                </Space>}
                                                <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                            </Space>
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addWorkUsedByIndustry()}
                            icon={<PlusOutlined/>}
                        >
                            Add Work Used By Industry
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Requests For Data, Code or Papers</Title>
                <p>Did you receive emails from others that asks from your code,
                    data or papers? or you were invited to work for a company
                    because of your research?
                </p>
                <Form.List name="requestsFor">
                    {(fields, {add, remove}) => {
                        addRequestsForDataCodeOrPapers = add;
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("requestsFor", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => (
                                    <div className={'edu-history'} key={index}>
                                        <Title level={5}>*requests for-{index + 1}</Title>
                                        <Space align={'start'}>
                                            <div>
                                                <div className={'edu-history-item'}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'requesterName']}
                                                                label='Requester Name'
                                                            >
                                                                <Input/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'aboutRequester']}
                                                                label='About Requester'
                                                            >
                                                                <Input.TextArea rows={4}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'summaryOfRequest']}
                                                                label={<Tooltip
                                                                    title={'Short summary of what the request asks for,and why your work is important to them'}>Summary
                                                                    Of Request</Tooltip>}
                                                            >
                                                                <Input.TextArea rows={4}/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'relatedResearch']}
                                                                label='Related Research'
                                                            >
                                                                <Input.TextArea rows={2}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Title level={5}>Request Proof</Title>
                                                <UploadFileBox form={form} fieldName={'requestsFor'}
                                                               index={index} label={'requestsFor_proof'}/>
                                            </div>
                                            <Space direction={"vertical"}>
                                                {fields.length > 1 && <Space direction={"vertical"}>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, -1, fields, 'requestsFor')}>Up</Button>
                                                    <Button type="dashed"
                                                            onClick={() => moveItem(form, index, 1, fields, 'requestsFor')}>Down</Button>
                                                </Space>}
                                                <Button onClick={() => customRemove(name)}>Remove</Button>
                                            </Space>
                                        </Space>
                                    </div>
                                ))}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addRequestsForDataCodeOrPapers()}
                            icon={<PlusOutlined/>}
                        >
                            Add Requests For Data, Code or Papers
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
            </Form>

            <Button style={{margin: '0 8px'}} onClick={prev}>Prev</Button>
            <Button type={"primary"} onClick={onSubmit}>Submit</Button>
        </div>
    )
}

export default IndustryAdoption