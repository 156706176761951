import React from 'react';
import page1 from "@/assets/svg/page1.svg"
import bulk11 from "@/assets/svg/bulk11.svg"
import self1 from "@/assets/svg/self-1.svg"
import self2 from "@/assets/svg/self-2.svg"
import self3 from "@/assets/svg/self-3.svg"
import com from "@/assets/svg/com.svg"
import ai from "@/assets/svg/ai.svg"
import st from "@/assets/svg/st.svg"
import big from "@/assets/svg/big.svg"
import tn from "@/assets/svg/tn.svg"
import chat from "@/assets/svg/chat.svg"
import "./index.scss"
import {Button, Card, Carousel, Flex, Image, Space, Typography} from "antd";
import {LatestPosts, nav, proceedFlow} from "@/data/nav";
import {Link} from "react-router-dom";
import {ArrowRightOutlined, FacebookOutlined, TwitterOutlined} from "@ant-design/icons";


const {Title, Text} = Typography;
const Test = () => {




    return (
        <div>
            <div className='home-hero'>
                <div className={'home-pic'}>
                    <Flex justify='space-between' align={"center"}>
                        <div style={{marginLeft: 250}}>
                            <Space direction={"vertical"} size={'large'}>
                                <Title style={{color: "white", fontSize: 55}}>
                                    Your Immigration Journey Starts Here
                                </Title>
                                <Title level={4} style={{color: "white"}}>
                                    Track, analyze, and get consultation on your immigration/visa cases.
                                </Title>

                                <Button shape={"round"}
                                        href={'/app/chat'}
                                        style={{
                                    backgroundColor: "black",
                                    border: "none",
                                    color: "white",
                                    height: '3rem'
                                }}> Evaluate Your Case</Button>

                            </Space>
                        </div>
                        <div>
                            <Image src={page1} preview={false}/>
                        </div>
                    </Flex>

                </div>
            </div>
            <div className={'home-intro'}>

                <div style={{color: 'white', fontSize: '3rem', margin: '2rem 0'}}>
                    <h>Typical Petition Flow</h>
                </div>

                <Space size={'large'}>
                    {proceedFlow.map((item, index) => (
                        <Flex key={index} justify={"center"} align={"center"} vertical={true}
                              className={`home-intro-card`}>
                            <div className="step-circle flexCenter">
                                <Title level={1} className="circle-number">
                                    {item.step}
                                </Title>
                            </div>
                            <Title level={3} className="card-title">
                                {item.title}
                            </Title>
                            <Text className="card-description">{item.total}</Text>
                        </Flex>
                    ))}
                </Space>
                <div style={{color: '#00bf63', fontSize: '3rem', marginTop: '4rem', textAlign: 'center'}}>
                    <h>ALL In One Immigration Platform</h>
                </div>
            </div>
            <div className={'home-self flexColumn'}>

                <Title level={1}>
                    Self-evaluation
                </Title>
                <Title level={5}>Free AI Chatbot offers professional background assessments and advice
                    without the need for a lawyer.
                </Title>
                <br/>
                <br/>
                <Space>
                    <Image preview={false} src={self1}/>
                    <Image preview={false} src={self2}/>
                    <Image preview={false} src={self3}/>
                </Space>
            </div>
            <div className={'home-quick flexColumn'}>
                <Space>
                    <Image src={bulk11} preview={false} width={150}/>
                    <div style={{color: 'green', fontSize: '3rem', margin: '2rem 0'}}>
                        <h>QuickFiling</h>
                    </div>
                </Space>
                <Title level={5} style={{color: 'white'}}>Fill out your personal details, and our advanced AI
                    Quickfiling swiftly generates
                    application documents, with tailored self-help packages for different backgrounds.
                </Title>
                <br/>
                <br/>
                <br/>
                <div className={'flexCenter'}>
                    <Space align={"center"} direction={'vertical'}>
                        <div className={'flexCenter'}>
                            <div style={{width: '70%'}}>
                                <Title level={4} style={{color: 'green'}}>Efficiently Gather Personal
                                    Achievements</Title>
                                <Title level={5} style={{color: 'white'}}>
                                    Use an streamlined module to efficiently
                                    collect your achievements, clearly highlighting
                                    your significant contributions.
                                </Title>
                            </div>
                        </div>
                        <div className={'flexCenter'}>
                            <div style={{width: '70%'}}>
                                <Title level={4} style={{color: 'green'}}>Efficiently Gather Personal
                                    Achievements</Title>
                                <Title level={5} style={{color: 'white'}}>
                                    Use an streamlined module to efficiently
                                    collect your achievements, clearly highlighting
                                    your significant contributions.
                                </Title>
                            </div>
                        </div>
                        <Image src={st} width={500} preview={false}/>
                    </Space>
                    <br/>
                    <br/>
                    <Space align={"center"} direction={'vertical'}>
                        <div className={'flexColumn'}>
                            <div style={{width: '70%'}}>
                                <Title level={4} style={{color: 'green'}}>Efficiently Gather Personal
                                    Achievements</Title>
                                <Title level={5} style={{color: 'white'}}>
                                    Use an streamlined module to efficiently
                                    collect your achievements, clearly highlighting
                                    your significant contributions.
                                </Title>
                            </div>
                        </div>
                        <div className={'flexColumn'}>
                            <div style={{width: '70%'}}>
                                <Title level={4} style={{color: 'green'}}>Efficiently Gather Personal
                                    Achievements</Title>
                                <Title level={5} style={{color: 'white'}}>
                                    Use an streamlined module to efficiently
                                    collect your achievements, clearly highlighting
                                    your significant contributions.
                                </Title>
                            </div>
                        </div>
                        <Image src={ai} width={500} preview={false}/>
                    </Space>

                </div>

            </div>
            <div className={'home-expert '}>
                <div className={'flexColumn'}>
                    <Space>
                        <div style={{color: 'green', fontSize: '2.5rem', margin: '1rem 0'}}>
                            <h>Certified Expert Double Check</h>
                        </div>
                    </Space>
                    <Title level={5} style={{color: 'black'}}>
                        Receive personalized support from
                        experienced specialist who guide you
                        through the application process and
                        efficiently resolve any issues.
                    </Title>
                </div>
                <div style={{margin: '2rem 0'}}>
                    <Carousel
                        dots={false}
                        infinite={true}
                        autoplay={true}
                        slidesToShow={4}
                        draggable
                        className="custom-carousel"
                    >
                        {LatestPosts.map((item, index) => (
                            <div key={index} className="carousel-card">
                                <Card
                                    hoverable
                                    cover={<img alt={item.title} src={item.image}/>}
                                    className="custom-card"

                                >
                                    <h3>{item.title}</h3>
                                    <p>{item.date}</p>
                                    <p>{item.description}</p>
                                    <Button type="link" icon={<ArrowRightOutlined/>}>Read More</Button>
                                </Card>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className={'flexColumn'}>
                    <Space direction={'vertical'} align={"center"} size={'large'}>
                        <Title level={4}>
                            Become a certified PL expert, help others and earn money
                        </Title>
                        <Button shape={"round"} style={{
                            backgroundColor: "#1dcd8d",
                            border: "none",
                            color: "white",
                            height: '3rem'
                        }}> Authenticate</Button>
                    </Space>

                </div>
            </div>
            <div className={'home-community flexCenter'}>

                <Space size={'large'}>
                    <div>
                        <Space direction={'vertical'} align={"center"} size={'large'}>
                            <Image preview={false} src={com} width={400}/>
                            <br />
                            <br />
                            <div className={'flexCenter'}>
                                <div style={{width: '60%'}}>
                                    <Space>
                                        <Image preview={false} src={big} width={80}/>
                                        <Title level={4} style={{color: 'green'}}>CaseTracker</Title>
                                    </Space>
                                    <div>
                                        <Title level={5} style={{color: 'white'}}>
                                            Use an streamlined module to efficiently
                                            collect your achievements, clearly highlighting
                                            your significant contributions.
                                        </Title>
                                    </div>
                                </div>
                            </div>
                        </Space>
                    </div>
                    <div>
                        <Space direction={'vertical'} align={"center"} size={'large'}>
                            <div className={'flexCenter'}>
                                <div style={{width: '60%'}}>
                                    <Space>
                                        <Image preview={false} src={chat} width={80}/>
                                        <Title level={4} style={{color: 'green'}}>Community</Title>
                                    </Space>
                                    <div>
                                        <Title level={5} style={{color: 'white'}}>
                                            Explore a wealth of real-life cases and
                                            experiences from applicants at various stages,
                                            helping you gain insights and avoid common mistakes.
                                        </Title>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <Image preview={false} src={tn} width={400}/>
                        </Space>
                    </div>
                </Space>
            </div>
            <div className={'home-footer'}>
                <div className={'flexColumn'}>
                    <Title level={1} style={{color: 'white'}}>GreenBoat</Title>
                    <Title level={5} style={{color: 'white'}}>The services and information provided by Quickfiling LLC are for informational purposes and should not be seen as legal advice. Quickfiling LLC is not a law firm and does not offer legal representation. Our services are not a substitute for the advice of a licensed attorney. Quickfiling LLC is not affiliated with any governmental agencies, including USCIS. Fees charged are for our services only and do not cover any government filing fees.</Title>
                </div>
                <Flex justify={'space-between'} align={'end'}>
                    <Space direction={'vertical'}  size={'large'}>
                        <Title level={1} style={{color: 'white'}}>Contact Us</Title>
                        <Title level={3} style={{color: 'white'}}>Phone 123-456-789</Title>
                        <Title level={3} style={{color: 'white'}}>Email hello@greenboat.com</Title>
                    </Space>
                    <Space align={"baseline"}>
                        <div> <Title level={3} style={{color: 'white'}}>Social</Title></div>
                        <Space size={'large'}>
                            <FacebookOutlined  style={{fontSize: 25}}/>
                            <TwitterOutlined style={{fontSize: 25}} />
                        </Space>
                    </Space>
                </Flex>
            </div>
        </div>
    )
}


export default Test;