
import h1 from "@/assets/svg/chatbot.svg";
import h2 from "@/assets/svg/genFile.svg";
import h3 from "@/assets/svg/professional.svg";
import h4 from "@/assets/svg/community.svg";
import {
    CheckCircleOutlined,
    CommentOutlined,
    DesktopOutlined,
    FileTextOutlined,
    RobotOutlined,
    RocketOutlined
} from "@ant-design/icons";
import t1 from "@/assets/agent/team-1.jpg";
import t2 from "@/assets/agent/team-2.jpg";
import t3 from "@/assets/agent/team-3.jpg";
import t4 from "@/assets/agent/team-4.jpg";
import rfe from "@/assets/svg/rfe.svg"
import expert from "@/assets/svg/expert.svg"
import support from "@/assets/svg/support.svg"
import checkItem from "@/assets/svg/checkItem.svg"
import React from "react";





export const Features = [
    {
        title: "All-in-one Platform",
        icon: <DesktopOutlined style={{fontSize: '40px', color: '#5b6060'}}/>,
        content: "Access all features and experts on one platform, ensuring a quick submission and comprehensive support for your immigration journey."
    },
    {
        title: "Self-evaluation",
        icon: <FileTextOutlined style={{fontSize: '40px', color: '#5b6060'}}/>,
        content: "Free AI Chatbot offers professional background assessments and advice without the need for a lawyer."
    },
    {
        title: "QuickFiling",
        icon: <CommentOutlined style={{fontSize: '40px', color: '#5b6060'}}/>,
        content: "Fill out your personal details, and our advanced AI Quickfiling swiftly generates application documents, with tailored self-help packages for different backgrounds."
    },
    {
        icon: <DesktopOutlined style={{fontSize: '40px', color: '#5b6060'}}/>,
        title: "Professional Services",
        content: "Receive personalized support from experienced PhDs who guide you through the application process and efficiently resolve any issues."
    },
    {
        title: "Community",
        icon: <FileTextOutlined style={{fontSize: '40px', color: '#5b6060'}}/>,
        content: "Explore a wealth of real-life cases and experiences from applicants at various stages, helping you gain insights and avoid common mistakes."
    },
    {
        title: "Case Tracker",
        icon: <CommentOutlined style={{fontSize: '40px', color: '#5b6060'}}/>,
        content: "Track your case status in real-time and receive immediate updates on your application’s progress"
    }
];






export const QuickFilingItems = [
    {
        icon: <FileTextOutlined style={{ fontSize: '40px'}} />,
        title: "Efficiently Gather Personal Achievements",
        content: "Use an streamlined module to efficiently collect your achievements, clearly highlighting your significant contributions."
    },
    {
        icon: <RobotOutlined style={{ fontSize: '40px' }} />,
        title: "Expertly Analyze Evidence Impact",
        content: "Automatically analyze publications, citations, projects, awards, and other evidence to emphasize your influence in the field."
    },
    {
        icon: <RocketOutlined style={{ fontSize: '40px'}} />,
        title: "Quickly Generate Key Documents",
        content: "Generate summary of contributions and petition letters in minutes, and quickly compile all letters, evidence, and forms into a ready-to-submit package."
    },
    {
        icon: <CheckCircleOutlined style={{ fontSize: '40px'}} />,
        title: "First Experience Free",
        content: "First-time users can receive a summary of contributions for free. Try it today!"
    },
]





export const LatestPosts = [
    {
        title: "Express Entry vs. PNPs",
        date: "29 August 2024",
        description: "Which Pathway is Right For You?",
        image: t1,
    },
    {
        title: "Immigration Misconceptions",
        date: "29 August 2024",
        description: "6 Myths Debunked",
        image: t2,
    },
    {
        title: "Express Entry vs. PNPs",
        date: "29 August 2024",
        description: "Which Pathway is Right For You?",
        image: t1,
    },
    {
        title: "Immigration Misconceptions",
        date: "29 August 2024",
        description: "6 Myths Debunked",
        image: t2,
    },
    {
        title: "Immibot vs ChatGPT",
        date: "16 August 2024",
        description: "What Are The Differences?",
        image: t3,
    },
    {
        title: "Another Post",
        date: "01 September 2024",
        description: "A new immigration topic.",
        image: t4,
    },
];


export const proceedFlow = [
    {
        cover: h1,
        total: "Connect with our AI Chatbot to explore immigration policies and receive a personalized assessment.",
        step: "1",
        title: "Self-Evaluation"
    },
    {
        cover: h2,
        title: "AI-Generation",
        step:"2",
        total: "Utilize our AI QuickFiling to create personalized documents, saving time, effort, and money.",
    },
    {
        cover: h3,
        title: "Specialist Check",
        step: "3",
        total: "Access Professional Services whenever you need it, ensuring your journey is guided by professionals.",
    },
    {
        cover: h4,
        step: "4",
        title: "Share & Track Case",
        total: "Join the Community to learn and share experiences, and use Case Tracker to stay informed of case progress",
    },
]


export const nav = [

    {
        text: "Evaluation",
        path: "/chat",
    },
    {
        text: "QuickFiling",
        path: "/quickfiling",
    },
    {
        text: "Specialist",
        path: "/specialist",
    },
    {
        text: "Community",
        path: "/community",
    },
    {
        text: "CaseTracker",
        path: "/track",
    },
]


export const footer = [
    {
        index: 1,
        title: "LAYOUTS",
        text: [{ list: "Home Page", id: 1 }, { list: "About Page", id: 2 },  { list: "Contact Page" , id: 3}],
    },
    {
        index: 2,
        title: "ALL SECTIONS",
        text: [ { list: "Features", id: 1 }, { list: "Attractive", id: 2 }],
    },
    {
        index: 3,
        title: "COMPANY",
        text: [{ list: "About", id: 1 }, { list: "Blog", id: 2 }, { list: "Pricing", id: 3 }],
    },
]


export const professionalService = [
    {
        icon: expert,
        description: "Our team consists of PhDs from diverse backgrounds with extensive experience in the application process, ensuring expert guidance tailored to your unique situation.",
    },
    {
        icon: checkItem,
        description: "We carefully review and refine your application materials, providing detailed feedback to enhance your submission's strength and accuracy.",
    },
    {
        icon: support,
        description: "We are available to promptly address any questions or concerns, ensuring you feel supported every step of the way throughout the preparation process."
    },
    {
        icon: rfe,
        description: "If you receive a Request for Evidence (RFE) after submission, don’t hesitate to contact us; we’ll work closely with you to respond effectively and keep your application on track."
    },

]



export const dropDownMenu = [
    {
        label: 'Recent',
        key: 'Recent',
    },
    {
        label: 'Top',
        key: 'Top',
    },
];



