import React, {useContext, useEffect} from 'react';
import {Form, Input, Button, Space, Select, DatePicker, Row, Col, Divider, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import "./index.scss"
import UploadFileBox from "@/components/common/dragUpload";
import {removeCurrentFormItem} from "@/utils/tool";
import {moveItem, StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm"
import {setEducations, setEmployments} from "@/store/modules/quick";
import {useDispatch, useSelector} from "react-redux";

const {Option} = Select;

const {Title} = Typography;

const EduEmployStepForm = () => {

    let addEducation;  // 定义一个变量保存 `add` 方法
    let addEmployment;  // 定义一个变量保存 `add` 方法
    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickForm = useSelector(state => state.quickForm.quickFilingForm);

    useEffect(() => {
        form.setFieldsValue({
            educations: quickForm.educations || [],
            employments: quickForm.employments || [],
        });
    }, [])

    const onFinishEduEmp = async () => {
        await setForm2Redux()
        setCurrent(current + 1);
    }

    const setForm2Redux = async () => {
        try {
            // 验证表单数据
            const values = await form.validateFields();
            const updatedEdu = [
                // ...quickForm.educations,
                ...values.educations,
            ];
            dispatch(setEducations(updatedEdu));
            const updatedEmp = [
                // ...quickForm.employments,
                ...values.employments,
            ];
            dispatch(setEmployments(updatedEmp));
        } catch (error) {
            console.error('Validation Failed:', error);
        }
    }

    const prev = async () => {
        await setForm2Redux()
        setCurrent(current - 1);
    }

    return (
        <div className={'eduEmployForm'}>
            <Form form={form} layout="vertical">
                <div>
                    <div>
                        <Title level={3}>Education & Employment</Title>
                    </div>
                    <Divider/>
                    <Title level={4}>Education History</Title>
                    <p>Add your highest degree only</p>
                    <br/>
                    <Form.List name="educations">
                        {(fields, {add, remove}) => {
                            // addEducation = add;
                            addEducation = add
                            const customRemove = (name, index) => {
                                remove(name);
                                removeCurrentFormItem("educations", index)
                            }
                            return (
                                <>
                                    {fields.map(({key, name, ...restField}, index) => {
                                        return (
                                            <div className={'edu-history'} key={index}>
                                                <Title level={5}>*education-{index + 1}</Title>
                                                <Space align={'start'}>
                                                    <div>
                                                        <div className={'edu-history-item'}>
                                                            <Row gutter={16}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'degree']}
                                                                        // rules={[{required: true, message: 'please input degree'}]}
                                                                        label='Degree'
                                                                    >
                                                                        <Select>
                                                                            <Option value="bachelor">Bachelor</Option>
                                                                            <Option value="master">Master</Option>
                                                                            <Option value="phd">PhD</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'major']}
                                                                        // rules={[{required: true, message: 'please input major'}]}
                                                                        label='Major'
                                                                    >
                                                                        <Input/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={16}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'university']}
                                                                        // rules={[{required: true, message: 'please input university'}]}
                                                                        label='University'
                                                                    >
                                                                        <Input/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'graduationYear']}
                                                                        label='Graduation Year'
                                                                    >
                                                                        <DatePicker format="YYYY-MM-DD"
                                                                                    showTime={false}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Title level={5}>Diploma File</Title>
                                                        <UploadFileBox form={form} fieldName={'educations'}
                                                                       index={index} label={"education_proof"}/>
                                                    </div>
                                                    <Space direction={"vertical"}>
                                                        {fields.length > 1 && <Space direction={"vertical"}>
                                                            <Button type="dashed"
                                                                    onClick={() => moveItem(form, index, -1, fields, 'educations')}>Up</Button>
                                                            <Button type="dashed"
                                                                    onClick={() => moveItem(form, index, 1, fields, 'educations')}>Down</Button>
                                                        </Space>}
                                                        <Button
                                                            onClick={() => customRemove(name, index)}>Remove</Button>
                                                    </Space>
                                                </Space>
                                            </div>
                                        )
                                    })}
                                </>
                            )
                        }}
                    </Form.List>
                    <div style={{marginTop: 10}}>
                        <Form.Item>
                            <Button
                                onClick={() => addEducation()}
                                icon={<PlusOutlined/>}
                            >
                                Add Education
                            </Button>
                        </Form.Item>
                    </div>
                </div>
                <Divider/>
                {/*雇佣信息*/}
                <div>
                    <div>
                        <Title level={4}>Employment History</Title>
                    </div>
                    <br/>
                    <Form.List name="employments">
                        {(fields, {add, remove}) => {
                            addEmployment = add
                            const customRemove = (name, index) => {
                                remove(name);
                                removeCurrentFormItem("employments", index)
                            }
                            return (
                                <>
                                    {fields.map(({key, name, ...restField}, index) => {
                                        return (
                                            <div className={'edu-history'} key={index}>
                                                <Title level={5}>*employment-{index + 1}</Title>
                                                <Space align={'start'}>
                                                    <div>
                                                        <div className={'edu-history-item'}>
                                                            <Row gutter={16}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'employer']}
                                                                        label='Employment Name'
                                                                    >
                                                                        <Input/>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'position']}
                                                                        label='Position Title'
                                                                    >
                                                                        <Input/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'jobDuty']}
                                                                        label='Job Duty'
                                                                    >
                                                                        <Input.TextArea rows={6}/>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Title level={5}>Employment Letter</Title>
                                                        <UploadFileBox form={form} fieldName={'employments'}
                                                                       index={index} label={'employment_proof'}/>
                                                    </div>
                                                    <Space direction={"vertical"}>
                                                        {fields.length > 1 && <Space direction={"vertical"}>
                                                            <Button type="dashed"
                                                                    onClick={() => moveItem(form, index, -1, fields, 'employments')}>Up</Button>
                                                            <Button type="dashed"
                                                                    onClick={() => moveItem(form, index, 1, fields, 'employments')}>Down</Button>
                                                        </Space>}
                                                        <Button
                                                            onClick={() => customRemove(name, index)}>Remove</Button>
                                                    </Space>
                                                </Space>
                                            </div>
                                        )
                                    })}
                                </>
                            )
                        }}
                    </Form.List>
                    <div style={{marginTop: 10}}>
                        <Form.Item>
                            <Button
                                onClick={() => addEmployment()}
                                icon={<PlusOutlined/>}
                            >
                                Add Employment
                            </Button>
                        </Form.Item>
                    </div>
                </div>
                <Divider/>
            </Form>
            <Button style={{margin: '0 8px'}} onClick={prev}>Prev</Button>
            <Button type={"primary"} onClick={onFinishEduEmp}>Next</Button>
        </div>
    );
};
export default EduEmployStepForm;


