import {Button, Col, Flex, Row, Space, Typography} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";


const { Title} = Typography;

const ImigraBoost = () => {


    return (
        <div className="plan-item">
            <div className={'plan-item-title'}>
                <h2>ImmigraBoost</h2>
                <div className={'free-badge'}>Free</div>
            </div>
            <div className="plan-item-content">
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div className={'plan-item-feature'}>
                            <Title level={4}>Features</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Accumulate achievements early with a smart template to
                                file your immigration
                                petition without rushing</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Identify preprints related to your ongoing
                                research</Title>
                            <Title level={5}><CheckCircleFilled  className="icon-spacing" />Identify related preprints and promote your work to
                                boost citations</Title>
                        </div>
                    </Col>

                    <Col span={7}>
                        <Title level={4}>Pricing</Title>
                        <br/>
                        <Flex justify={'space-between'}>
                            <div><Title level={5}>ImmigraBoost</Title></div>
                            <div><Title level={5}>$0 / Month</Title></div>
                        </Flex>
                    </Col>
                    <Col span={9}>
                        <Flex className={'imigraBoost-content-button'} justify={'center'} align={'center'} >
                            <div>
                                <Space direction={'vertical'}>
                                    <Button style={{width: '100%'}} type={"primary"}>Boost Now!</Button>
                                    <Button style={{width: '100%'}} type={"primary"}>Smart Form</Button>
                                    <Button style={{width: '100%'}} type={"primary"}>Recommendation Letter</Button>
                                </Space>
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ImigraBoost