import React from 'react';
import { AntDesignOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import { createStyles } from 'antd-style';

const useStyle = createStyles(({ prefixCls, css }) => ({
    linearGradientButton: css`
        &.${prefixCls}-btn-primary:not([disabled]):not(.${prefixCls}-btn-dangerous) {
            border-width: 0;

            > span {
                position: relative;
            }

            &::before {
                content: '';
                background: linear-gradient(135deg, #8732bf, #22bc1f);

                position: absolute;
                inset: 0;
                opacity: 1;
                transition: all 0.3s;
                border-radius: inherit;
            }

            &:hover::before {
                background: linear-gradient(135deg, #d01264, #6f0fce);
                opacity: 1; /* 保持渐变背景可见 */
            }
        }
    `,
}));

const CustomButton = ({ text, onClick }) => {
    const { styles } = useStyle();

    return (
        <ConfigProvider
            button={{
                className: styles.linearGradientButton,
            }}
        >
                <Button onClick={onClick} type="primary" size="large" icon={<AntDesignOutlined />}>
                    {text}
                </Button>

        </ConfigProvider>
    );
};

export default CustomButton;
