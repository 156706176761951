import React, {useContext, useEffect} from 'react';
import {Button, Flex, Input, Space, Typography} from "antd";
import CustomButton from "@/components/common/customButton";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import {useNavigate} from "react-router-dom";


const {Title, Paragraph} = Typography;
const {TextArea} = Input;


const Endeavor = () => {

    const {setMainCurrent} = useContext(MainStepContext);
    const navigate = useNavigate();


    useEffect(()=>{
        setMainCurrent(4)
    }, [])


    const generateReport = () => {
        navigate('/quickfiling/process/generateReport')
    }

    return (
        <div className="review-citation">

            <Flex justify="space-between">
                <div >
                    <Title level={2}>Generate proposed endeavor</Title>
                    <Paragraph>You can generate proposed endeavor by using GreenBoat's AI</Paragraph>
                </div>
                <Space>
                    <Button type={"default"} size={"large"}>Save endeavor</Button>
                    <Button type={"primary"} size={"large"} onClick={generateReport}>Generate Report</Button>
                </Space>
            </Flex>
            <br />

            <div>
                <Flex vertical={true} align={'center'}>
                    <CustomButton text={'Generate with GreenBoat AI'}/>
                    <br/>
                    <TextArea
                        autoSize={{
                            minRows: 20,
                            maxRows: 20,
                        }}
                        style={{width: '40%'}}
                    >

                    </TextArea>
                </Flex>

            </div>

        </div>
    )
}


export default Endeavor