import React, {useEffect, useRef, useState} from "react";
import Post from "src/components/communityComp/post"
import {
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Dropdown,
    Modal,
    Row,
    Skeleton,
    Space
} from "antd";
import Profile from "src/components/communityComp/profile";
import SiteInfo from "@/components/siteInfo/siteInfo";
import {useSelector} from 'react-redux'
import {getQuestionAPI} from "@/apis/question";
import {DownOutlined, ReadOutlined, ScheduleOutlined} from "@ant-design/icons";
import "./index.scss"
import StartPost from "src/components/communityComp/startPost";
import PriorityDate from "@/components/communityComp/startPost/priorityDate";
import TopArticle from "src/components/communityComp/topArticle";
import {dropDownMenu} from "@/data/nav";

const Community = () => {

    //问题列表数据
    const [posts, setPosts] = useState([]);
    //page
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState('Top');
    const [open, setOpen] = useState(false);
    const [selectedPostType, setSelectedPostType] = useState('');

    useEffect(() => {
        setLoading(true);
        getQuestionAPI(page, 20, sortType).then(
            response => {
                if (response.code === 0) {
                    const fetchedPosts = response.data.list || [];
                    // 判断是否有更多数据
                    if (fetchedPosts.length < 20 || posts.length + fetchedPosts.length >= response.data.count) {
                        setHasMore(false);  // 如果没有更多数据，停止请求
                    }
                    setPosts((prev) => [...prev, ...fetchedPosts]);
                }
                setLoading(false);
            },
        ).catch(
            error => {
                console.log(error);
            }
        )
    }, [page]);


    const observer = useRef();
    const loadMoreRef = useRef();

    useEffect(() => {
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                setPage((prev) => prev + 1);
            }
        });
        if (loadMoreRef.current) {
            observer.current.observe(loadMoreRef.current);
        }
        return () => {
            if (loadMoreRef.current) {
                observer.current.unobserve(loadMoreRef.current);
            }
        };
    }, [loading]);


    //用户信息
    const isLogin = useSelector(state => state.user.token);
    const userInfo = useSelector(state => state.user.userInfo);

    const handleDropDownClick = (e) => {
        setSortType(e.key);
        setPage(1)
        setLoading(true);
        getQuestionAPI(page, 20, e.key).then(
            response => {
                if (response.code === 0) {
                    const fetchedPosts = response.data.list || [];
                    // 判断是否有更多数据
                    if (fetchedPosts.length < 20 || posts.length + fetchedPosts.length >= response.data.count) {
                        setHasMore(false);  // 如果没有更多数据，停止请求
                    }
                    setPosts(fetchedPosts);
                }
                setLoading(false);
            },
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    const handleStartPost = (value) => {
        setOpen(true)
        setSelectedPostType(value)
    }

    const renderContent = () => {
        switch (selectedPostType) {
            case 'StartPost':
                return <StartPost setOpen={setOpen}/>;
            case 'PD':
                return <PriorityDate setOpen={setOpen}/>;
            default:
                return null;
        }
    };



    return (
        <div className={'community'}>
            <div className={'flexCenter'} >
                <Row style={{width: '70%', marginTop: '10px'}} gutter={20}>
                    <Col lg={5} xs={0}>
                        <div style={{position: "sticky", top: "60px"}}>
                            {isLogin ? <Profile/> : null}
                        </div>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Card bordered={true}>
                            <Button size={'large'}
                                    block={true}
                                    shape={'round'}
                                    type={"text"}
                                    style={{border: "1px solid"}}
                                    onClick={() => handleStartPost('StartPost')}

                            >
                                start a post
                            </Button>
                            <div className={'flexCenter'}>
                                <div style={{width: '70%', marginTop: '10px'}} className={'flexBetween'}>
                                <span>
                                    <Button size={'large'}
                                            type={'text'}
                                            icon={<ScheduleOutlined/>}
                                            onClick={() => handleStartPost('PD')}>
                                        PriorityDate
                                    </Button>
                                </span>
                                    <span>
                                    <Button size={'large'}
                                            type={'text'}
                                            icon={<ReadOutlined/>}
                                            href={'/community/create'}
                                    >
                                        Article
                                    </Button>
                                </span>
                                </div>
                            </div>
                        </Card>
                        <Divider orientation={"right"}>
                            sort by
                            <Dropdown menu={{
                                items: dropDownMenu,
                                onClick: handleDropDownClick
                            }}
                                      trigger={["click"]}
                            >
                                <Button size={'small'} type={'text'} icon={<DownOutlined/>} iconPosition={'end'}>
                                    {sortType}
                                </Button>
                            </Dropdown>
                        </Divider>
                        <div>
                            {posts.map(post => (
                                <div className={'post-card'} key={post.id}>
                                    <Post closeButton={true} post={post}/>
                                </div>
                            ))}
                            <div ref={loadMoreRef} style={{height: '20px', visibility: 'hidden'}}></div>
                            <Skeleton active loading={loading}/>
                            {!hasMore && <div className={'flexCenter'}>~~~no more~~~</div>}
                        </div>

                    </Col>
                    <Col lg={7} xs={0}>
                        <TopArticle/>
                        <div style={{position: 'sticky', top: "60px"}}>
                            <SiteInfo/>
                        </div>
                    </Col>
                    {/*<Affix style={{position: 'fixed', bottom: '2rem', right: '25rem'}}>*/}
                    {/*    <Button type={"text"} size={'large'} onClick={() => window.scrollTo({*/}
                    {/*        top: 0,*/}
                    {/*        behavior: 'smooth'*/}
                    {/*    })}*/}
                    {/*            icon={<RocketOutlined/>}*/}
                    {/*    >*/}
                    {/*    </Button>*/}
                    {/*</Affix>*/}
                </Row>
                <Modal
                    footer={null}
                    open={open && isLogin}
                    width={600}
                    onCancel={() => setOpen(false)}
                    title={
                        <Space>
                            <Avatar size={55} src={userInfo?.avatar}/>
                            <h2>{userInfo?.username}</h2>
                        </Space>
                    }
                >
                    {renderContent()}
                </Modal>
            </div>
        </div>

    );
}

export default Community

