import React, {useEffect, useState} from 'react';
import {ExportOutlined, InboxOutlined, LinkOutlined} from '@ant-design/icons';
import {Button, Drawer, Flex, Image, message, Upload} from 'antd';
import {getOSSUrl, getPreviewLink, uploadFile2OSS} from "@/apis/quick";
import {Document, Page, pdfjs} from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "./index.scss"
import {useDispatch, useSelector} from "react-redux";
import {setField} from "@/store/modules/quick";
import cloneDeep from 'lodash/cloneDeep';


pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()


// 设置 workerSrc
const {Dragger} = Upload;


const FilePreview = ({objectKey, setExportPreviewLink}) => {

    const [numPages, setNumPages] = useState(null);
    const [previewLink, setPreviewLink] = useState(null);
    const [fileType, setFileType] = useState("pdf");
    const [scale, setScale] = useState(1.0); // 控制缩放比例
    // const containerRef = useRef(null); // 引用 PDF 容器

    useEffect(() => {
        const fileExtension = objectKey.split('.').pop().toLowerCase();
        setFileType(fileExtension)
        getPreviewLink(objectKey).then(res => {
            setPreviewLink(res.data.previewUrl)
            setExportPreviewLink(res.data.previewUrl)
        }).catch(
            err => console.log(err)
        )

    }, [objectKey])

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };
    const onPageLoadSuccess = (page) => {
        const pageWidth = page.view[2]; // 获取页面的宽度
        const calculatedScale = 540 / pageWidth;
        setScale(calculatedScale);
    };

    const renderPreview = () => {
        switch (fileType) {
            case 'pdf':
                return (
                    <div className={'pdf-container'}>
                        <Document file={previewLink} onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    scale={scale}
                                    onLoadSuccess={onPageLoadSuccess}
                                />
                            ))}
                        </Document>
                    </div>
                );

            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <Image
                        src={previewLink}
                        alt="Preview"
                        style={{width: '100%', maxHeight: '600px', objectFit: 'contain'}}
                    />
                );

            case 'doc':
            case 'docx':
                return (
                    <iframe
                        src={`https://docs.google.com/gview?url=${encodeURIComponent(previewLink)}&embedded=true`}
                        width="100%"
                        title="Word Document Preview"
                    />
                );
            default:
                return (
                    <div>
                        <p>File preview not supported for this format.</p>
                    </div>
                );
        }
    };
    return (
        <div>
            {renderPreview()}
        </div>
    )
}


const UploadFileBox = ({form, fieldName, index, label}) => {

    const [uploadedFileName, setUploadedFileName] = useState('');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [objectKey, setObjectKey] = useState(null);
    const [exportPreviewLink, setExportPreviewLink] = useState(null);
    const dispatch = useDispatch()
    const quickForm = useSelector(state => state.quickForm.quickFilingForm);


    useEffect(() => {
        const values = quickForm[fieldName]
        // console.log("quickForm---" , quickForm, "fieldName---", fieldName, "values---", values, "label---", label)
        if (Array.isArray(values) && typeof index === 'number' && values.length > index) {
            const fileObj = values[index][label];
            // console.log("array---fileObj---", fileObj, index, label)
            setUploadedFileName(fileObj?.originalName);
            setObjectKey(fileObj?.objectKey)
        } else if (typeof values === 'object'){
            const fileObj = values[label];
            // console.log("fileObj---", fileObj)
            setUploadedFileName(fileObj?.originalName);
            setObjectKey(fileObj?.objectKey)
        }
    }, [])

    const onDrop = (e) => {
        console.log('Dropped files', e.dataTransfer.files);
    }

    const onClickPreview = (e) => {
        // 阻止事件冒泡，防止点击 preview 按钮触发上传事件
        e.stopPropagation();
        setDrawerVisible(true);
    }

    const handleCustomRequest = async (options) => {
        const {file, onProgress} = options;

        try {
            const res = await getOSSUrl(file.name);
            // 使用 axios 进行上传请求
            await uploadFile2OSS(res.data.preSignUrl, file, onProgress)
            // 调用 onSuccess 告知上传组件上传成功
            setUploadedFileName(file.name);
            setObjectKey(res.data.objectKey); // 保存 objectKey
            message.success(`${file.name} file uploaded successfully.`);
            // 获取当前表单所有字段
            const original = form.getFieldsValue()
            let FormAllValues = cloneDeep(original)
            let currentFormValues = FormAllValues[fieldName]
            if (Array.isArray(currentFormValues)) {
                if (typeof index === 'number') {
                    // // 如果是空数组且 index 有效，扩展数组到指定的 index 大小
                    if (currentFormValues.length <= index) {
                        currentFormValues = [...currentFormValues, ...new Array(index - currentFormValues.length + 1).fill({})];
                    }
                    // 使用不可变更新方法替换指定 index 的元素
                    currentFormValues[index] = {
                        ...currentFormValues[index],
                        [label]: {
                            'originalName': file.name,
                            'objectKey': res.data.objectKey
                        },
                    };
                } else {
                    // 没有 index 时，直接追加元素到副本
                    currentFormValues = [...currentFormValues, {
                        [label]: {
                            'originalName': file.name,
                            'objectKey': res.data.objectKey
                        },
                    }];
                }
                // 更新 formValues 中的目标字段
                FormAllValues[fieldName] = currentFormValues;
                form.setFieldsValue(FormAllValues)
            } else if (typeof currentFormValues === 'object') {
                // 对象类型，合并值到新对象中
                currentFormValues = {
                    ...currentFormValues,
                    [label]: {
                        'originalName': file.name,
                        'objectKey': res.data.objectKey
                    },
                };
                // 更新 formValues 中的目标字段
                // FormAllValues[fieldName] = currentFormValues;
                FormAllValues = {
                    ...FormAllValues,
                    [fieldName] : currentFormValues,
                }
                form.setFieldsValue(FormAllValues)
            } else {
                currentFormValues = {
                    // 其他类型，直接赋值
                    ...FormAllValues,
                    [label]: {
                        'originalName': file.name,
                        'objectKey': res.data.objectKey
                    },
                };
                // 更新 formValues 中的目标字段
                FormAllValues = currentFormValues;
                form.setFieldsValue(FormAllValues)

            }
            // 写入 Redux store
            dispatch(setField({fieldName, value: currentFormValues}));
        } catch (error) {
            console.log(error);
            message.error(`${file.name} file upload failed.`);
        }
    };

    const exportPreview = () => {
        if (exportPreviewLink != null) {
            window.open(exportPreviewLink, '_blank');
        }
    }

    return (
        <div>
            <Dragger
                name={'file'}
                multiple={false}
                showUploadList={false}
                customRequest={handleCustomRequest} // 自定义上传请求
                onDrop={onDrop}
                accept=".pdf,.doc,.docx,.jpg,.png" // 限制可上传文件类型
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                {objectKey != null ? <div>
                        <div><LinkOutlined/>{uploadedFileName}</div>
                        <Button type={"primary"} onClick={(e) => onClickPreview(e)}>preview</Button>
                    </div> :
                    <div>
                        <p className="ant-upload-text">Upload your file</p>
                    </div>
                }
                <br/>
                <p className="ant-upload-hint">
                    Click to upload or drag and drop<br/>
                    Supported file: pdf, word, image
                </p>
            </Dragger>

            <Drawer
                classNames={'preview-drawer'}
                title={<div>
                    <Flex justify={'space-between'} align={'center'}>
                        <div>File Preview: {uploadedFileName}</div>
                        <ExportOutlined onClick={exportPreview}/>
                    </Flex>
                </div>}
                placement="right"
                width={600}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
            >
                {objectKey && (
                    <FilePreview objectKey={objectKey} setExportPreviewLink={setExportPreviewLink}/>
                )}
            </Drawer>
        </div>
    )
};
export default UploadFileBox;




// const handleUploadSuccess = (form, fieldName, index, fileUUID, link) => {
//     // 获取当前的表单字段值
//     const currentList = form.getFieldValue(fieldName) || [];
//
//     // 更新当前 index 对应的对象，设置 fileUUID
//     currentList[index] = {
//         ...currentList[index],
//         fileUUID: fileUUID,
//         viewLink: link,
//     };
//
//     // 使用 setFieldsValue 将更新后的字段值写回表单
//     form.setFieldsValue({
//         [fieldName]: currentList
//     });
// };