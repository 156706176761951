import React, {useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {Button, Flex, Table, Tag} from "antd";
import {TextMore} from "@/components/common/textMore";


const data = [


    {
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        title: true
    },
    {
        key: '1-1',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atoading of single atoading of single atoading of single atoading of single atoading of single atomic iron sites',
        judge: 'Positive',
        content: 'This was suoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atccessfully demonstrated in a proof-of-concept',
    },
    {
        key: '1-2',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atomic iron sites',
        judge: 'Positive',
        content: 'In the optimised Fe-NC caading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic irotalys',
    },

    {
        publication: '1111Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        title: true
    },
    {
        key: '2-1',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atoading of single atoading of single atoading of single atoading of single atoading of single atomic iron sites',
        judge: 'Positive',
        content: 'This was suoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atccessfully demonstrated in a proof-of-concept',
    },
    {
        key: '2-2',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atomic iron sites',
        judge: 'Positive',
        content: 'In the optimised Fe-NC caading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic irotalys',
    },
    {
        key: '3-1',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atoading of single atoading of single atoading of single atoading of single atoading of single atomic iron sites',
        judge: 'Positive',
        content: 'This was suoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atccessfully demonstrated in a proof-of-concept',
    },
    {
        key: '3-2',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atomic iron sites',
        judge: 'Positive',
        content: 'In the optimised Fe-NC caading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic irotalys',
    },
    {
        key: '4-1',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atoading of single atoading of single atoading of single atoading of single atoading of single atomic iron sites',
        judge: 'Positive',
        content: 'This was suoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atoading of single atccessfully demonstrated in a proof-of-concept',
    },
    {
        key: '4-2',
        publication: 'Nature Catalysis, 2022 - naoading of single atoading of single atoading of single atoading of single atture.com',
        citationTitle: 'High loading of single atomic iron sites',
        judge: 'Positive',
        content: 'In the optimised Fe-NC caading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic iroading of single atomic irotalys',
    },

];


const ReviewHighlightsTable = () => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };


    useEffect(() => {
        setDataSource(data)
    }, [])




    const columns = [
        {
            title: 'Publication',
            dataIndex: 'publication',
            key: 'publication',
            colSpan: 0,
            render: (text, record) => {
                // 渲染时检查是否为标题行
                if (record.title === true) {
                    return <Title level={5}>{text}</Title>;
                }
                return null;
            },
            onCell: (record) => {
                if (record.title === true) {
                    return {
                        colSpan: 4, // 跨越所有列
                    };
                }
                return { colSpan: 0 }; // 其他行隐藏此列的单元格
            },

        },
        {
            title: 'Citation Title',
            dataIndex: 'citationTitle',
            key: 'citationTitle',
            className: 'word-break',
            width: '10%',
            render: (text, record) => {
                if (record.title === true) {
                    return null; // 隐藏内容
                }
                return text;
            },
            onCell: (record) => {
                if (record.title === true) {
                    return {
                        colSpan: 0, // 此行隐藏此列
                    };
                }
            },
        },

        {
            title: 'Judge',
            dataIndex: 'judge',
            key: 'judge',
            render: (_, record) => {
                if (record.title === true) {
                    return null; // 隐藏内容
                }
                return <Tag color={'#87d068'}>{record.judge}</Tag>;
            },
            onCell: (record) => {
                if (record.title === true) {
                    return {
                        colSpan: 0, // 此行隐藏此列
                    };
                }
            },
        },

        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            width: '5%',
            render: (text, record) => {
                if (record.title === true) {
                    return null; // 隐藏内容
                }
                return <TextMore text={text} />;
            },
            onCell: (record) => {
                if (record.title === true) {
                    return {
                        colSpan: 0, // 此行隐藏此列
                    };
                }
            },
        },

    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            // 如果是 title 行，就不显示 checkbox
            style: record.title ? { display: 'none' } : {},
            disabled: record.title, // 不允许选中 title 行
        }),
    };

    return (
        <div>
            <Title level={3}>Review your highlights</Title>

            <Flex justify={"center"} align={"center"}
                  style={{width: 600, padding: '0 5px', height: 45, backgroundColor: '#e6e4e4', borderRadius: 10}}>
                <Button>
                    All Citations
                </Button>
                <Button>
                    Positive
                </Button>
                <Button>
                    Neutral
                </Button>
                <Button>
                    Negative
                </Button>
                <Button>
                    SelfCitation
                </Button>
            </Flex>

            <br/>
            <Table rowSelection={rowSelection}
                   columns={columns}
                   pagination={false}
                   dataSource={dataSource}
                   scroll={{
                       y: 500,
                   }}
            />
            <br/>
            <Flex justify={"center"}>
                <Button type={"primary"} size={"large"}>Confirm</Button>
            </Flex>
        </div>
    )
}


export default ReviewHighlightsTable