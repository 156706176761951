import {request} from "@/utils";



export function submitProfile(data) {
    return request({
        url: "/quickfiling/createProfile",
        method: "POST",
        data: data,
    })
}


export function getOSSUrl(fileName) {
    return request({
        url: "/quickfiling/getUploadUrl",
        method: "GET",
        params: {
            fileName: fileName,
        }
    })
}


export function uploadFile2OSS(url, file, onProgress) {
    return request({
        url: url,
        method: "PUT",
        data: file,
        timeout: 10000,
        headers: {
            'Content-Type': file.type, // 确保使用正确的 Content-Type
        },
        onUploadProgress: ({total, loaded}) => {
            onProgress({percent: Math.round((loaded / total) * 100)}, file);
        },
    })
}



export function getPreviewLink  (fileName)  {
    return request({
        url: "/quickfiling/getFilePreviewUrl",
        method: "GET",
        params: {
            fileName: fileName,
        }
    })
}

export function getQuickSideProfile  ()  {
    return request({
        url: "/quickfiling/geSideProfile",
        method: "GET",
    })
}


export function getQuickUserProfile  ()  {
    return request({
        url: "/quickfiling/getUserProfile",
        method: "GET",
    })
}


