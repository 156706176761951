import React, {useContext, useEffect} from 'react';
import {Button, Col, DatePicker, Divider, Form, Input, Row, Space, Tooltip} from "antd";
import {removeCurrentFormItem} from "@/utils/tool";
import UploadFileBox from "@/components/common/dragUpload";
import {moveItem, StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import {PlusOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import {setHonors, setMediaReports,  setPresentations} from "@/store/modules/quick";


const Honor_media_present = () => {

    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickFormData = useSelector(state => state.quickForm.quickFilingForm);

    let addHonors;
    let addMediaReports;
    let addPresentations;

    useEffect(() => {
        form.setFieldsValue({
            honors: quickFormData.honors || [],
            mediaReports: quickFormData.mediaReports || [],
            presentations: quickFormData.presentations || [],
        });
    }, [])

    const onFinish = async () => {
        await setForm2Redux()
        // 跳转到下一步
        setCurrent(current + 1);
    }

    const prev = async () => {
        await setForm2Redux()
        setCurrent(current - 1);
    }

    const setForm2Redux = async () => {
        try {
            // 验证表单数据
            const values = await form.validateFields();
            // 保存到 Redux
            const updatedHonors = [
                // ...quickFormData.honors,
                ...values.honors,
            ];
            dispatch(setHonors(updatedHonors));
            const updatedMediaReport = [
                // ...quickFormData.mediaReports,
                ...values.mediaReports,
            ];
            dispatch(setMediaReports(updatedMediaReport));
            const updatedPresent = [
                // ...quickFormData.presentations,
                ...values.presentations,
            ];
            dispatch(setPresentations(updatedPresent));

        } catch (error) {
            console.error('Validation Failed:', error);
        }
    }

    return (
        <div className={'eduEmployForm'}>
            <Form form={form} layout="vertical">
                <div>
                    <Title level={3}>Honors & Reports & Presentations</Title>
                </div>
                <Divider/>
                <Title level={4}>Honors</Title>
                <br/>
                <Form.List name="honors">
                    {(fields, {add, remove}) => {
                        addHonors = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("honors", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Honors-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'prizeName']}
                                                                    label='Honor Prize Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'prizeIntro']}
                                                                    label='Brief Intro Of Honor Or Prize'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'organization']}
                                                                    label='Organization'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'organizationIntro']}
                                                                    label='Brief Intro Of Organization'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'why']}
                                                                    label='Why You Won'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'receiveDate']}
                                                                    label='Received Date'
                                                                >
                                                                    <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Organization Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'honors'} index={index}
                                                                   label={'org_proof'}/>
                                                    <br/>
                                                    <Title level={5}>Honor Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'honors'} index={index}
                                                                   label={"honor_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'honors')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'honors')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addHonors()}
                            icon={<PlusOutlined/>}
                        >
                            Add Honor
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Media Reports</Title>
                <br/>
                <Form.List name="mediaReports">
                    {(fields, {add, remove}) => {
                        addMediaReports = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("mediaReports", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Media Reports-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={16}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'mediaName']}
                                                                    label='Media Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'mediaRanking']}
                                                                    label='Ranking'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'paper']}
                                                                    label={<Tooltip
                                                                        title={'Does it mention your research? if yes, which paper'}>Mentioned
                                                                        Research Paper</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'reportContent']}
                                                                    label='Report Content'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof Of Ranking</Title>
                                                    <UploadFileBox form={form} fieldName={'mediaReports'} index={index}
                                                                   label={"ranking_proof"}/>
                                                    <br/>
                                                    <Title level={5}>Proof of Media Report</Title>
                                                    <UploadFileBox form={form} fieldName={'mediaReports'} index={index}
                                                                   label={'report_proof'}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'mediaReports')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'mediaReports')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addMediaReports()}
                            icon={<PlusOutlined/>}
                        >
                            Add Media Reports
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Presentations</Title>
                <br/>
                <Form.List name="presentations">
                    {(fields, {add, remove}) => {
                        addPresentations = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("presentations", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Presentations-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'presentationType']}
                                                                    label='Presentation Type'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'presentationTitle']}
                                                                    label='Presentation Title'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'conferenceName']}
                                                                    label='Conference Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'conferenceIntro']}
                                                                    label='Intro of the Conference'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'receiveDate']}
                                                                    label='Received Date'
                                                                >
                                                                    <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Conference Letter</Title>
                                                    <UploadFileBox form={form} fieldName={'presentations'} index={index}
                                                                   label={"letter_proof"}/>
                                                    <br/>
                                                    <Title level={5}>Schedule Letter</Title>
                                                    <UploadFileBox form={form} fieldName={'presentations'} index={index}
                                                                   label={"schedule_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'presentations')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'presentations')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addPresentations()}
                            icon={<PlusOutlined/>}
                        >
                            Add Presentations
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
            </Form>
            <Button style={{margin: '0 8px'}} onClick={prev}>Prev</Button>
            <Button type={"primary"} onClick={onFinish}>Next</Button>
        </div>
    )
}
export default Honor_media_present