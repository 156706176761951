import React, {useEffect, useState} from 'react';
import {CustomerServiceOutlined, FileAddOutlined, HomeOutlined, PlusOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Button, Flex, FloatButton, Layout, Space} from 'antd';
import {Outlet, useNavigate} from "react-router-dom";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import { getQuickUserProfile} from "@/apis/quick";
import {
    setIntro,
    setEducations,
    setEmployments,
    setResearch,
    setUnlistedArticles,
    setMissingCitations,
    setHighlightComments,
    setBooks,
    setPatents,
    setFundingAwards,
    setHonors,
    setMediaReports,
    setPresentations,
    setReviewerExperiences,
    setProfessionalMemberships,
    setAwardFundingReviewerExperiences,
    setConsultingExperiences,
    setOpenSourceProjects,
    setWorkUsedByIndustry,
    setRequestsFor,
    setWorkUsedForCourses} from "@/store/modules/quick";

const {Content, Sider} = Layout;


const QuickFiling = () => {


    const [quickFormAuthorInfo, setQuickFormAuthorInfo] = useState(null)
    const nav = useNavigate()
    const quickForm = useSelector(state => state.quickForm);
    const dispatch = useDispatch()

    useEffect(() => {
        if (quickForm.sideProfile) {
            setQuickFormAuthorInfo(quickForm.sideProfile)
        } else {
            getQuickUserProfile().then(
                res => {
                    console.log("getQuickUserProfile---", res)
                    if (res.code === 0) {
                        setQuickFormAuthorInfo({
                            affiliations: res.data.affiliations,
                            avatarUrl: res.data.avatarUrl,
                            firstname: res.data.firstname,
                            lastname: res.data.lastname,
                            middleName: res.data.firstname,
                        })
                        dispatch(setIntro(res.data.intro || {}))
                        dispatch(setEducations(res.data.educations || []))
                        dispatch(setEmployments(res.data.employments || []))
                        dispatch(setResearch(res.data.research || {}))
                        dispatch(setUnlistedArticles(res.data.unlistedArticles || []))
                        dispatch(setMissingCitations(res.data.missingCitations || []))
                        dispatch(setHighlightComments(res.data.highlightComments || []))
                        dispatch(setBooks(res.data.books || []))
                        dispatch(setPatents(res.data.patents || []))
                        dispatch(setFundingAwards(res.data.fundingAwards || []))
                        dispatch(setHonors(res.data.honors ||[]))
                        dispatch(setMediaReports(res.data.mediaReports || []))
                        dispatch(setPresentations(res.data.presentations  || []))
                        dispatch(setReviewerExperiences(res.data.reviewerExperiences || []))
                        dispatch(setProfessionalMemberships(res.data.professionalMemberships || []))
                        dispatch(setAwardFundingReviewerExperiences(res.data.awardFundingReviewerExperiences || []))
                        dispatch(setConsultingExperiences(res.data.consultingExperiences || []))
                        dispatch(setOpenSourceProjects(res.data.openSourceProjects || []))
                        dispatch(setWorkUsedByIndustry(res.data.workUsedByIndustry || []))
                        dispatch(setRequestsFor(res.data.requestsFor || []))
                        dispatch(setReviewerExperiences(res.data.reviewerExperiences || []))
                        dispatch(setWorkUsedForCourses(res.data.workUsedForCourses || []))
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        }
    }, [])

    const onClickFloatButton = () => {

    }


    const updateAuthorInfo = () => {
        nav('/quickfiling/process/profile')
    }
    const deleteAuthorInfo = () => {
        setQuickFormAuthorInfo(null)
    }

    return (

        <div>
            <div style={{backgroundColor: '#f4f4f4'}}>
                <Layout>
                    <Layout>
                        <Sider width="15%" theme={"light"}>
                            {quickFormAuthorInfo !== null ? <div className={'quickfiling-profile'}>
                                    <Flex vertical={true} align={'center'}>
                                        <Flex vertical={true} align={'center'}>
                                            <div>
                                                <Avatar
                                                    src={quickFormAuthorInfo?.avatarUrl || ""}
                                                    size={120}
                                                    shape="square"
                                                    alt={"please login"}
                                                />
                                            </div>
                                            <br/>
                                            <h2>{quickFormAuthorInfo?.firstname || "harris"}</h2>
                                            <h2>{quickFormAuthorInfo?.lastname || "trump"}</h2>
                                            <br/>
                                            <div className={'ellipsis-text-2'}
                                                 style={{maxWidth: '10rem'}}>{quickFormAuthorInfo?.affiliations || "USA"}</div>
                                        </Flex>
                                        <div style={{marginTop: 16}}>
                                            <Space direction={"vertical"} size={"large"}>
                                                <Button type="primary" style={{width: 120}} onClick={updateAuthorInfo}>Update
                                                    Profile</Button>
                                                <Button type="primary" style={{width: 120}} onClick={deleteAuthorInfo}>Delete
                                                    Profile</Button>
                                                <Button type="primary" style={{width: 120}}>Bill</Button>
                                            </Space>
                                        </div>
                                    </Flex>
                                </div> :
                                <Flex vertical={true} justify={"center"} align="center" style={{paddingTop: 75}}>
                                    <div style={{fontSize: 48}}><FileAddOutlined/></div>
                                    <div style={{width: '60%', textAlign: "center"}}>
                                        <Title level={5}>Create your first profile to get started!</Title>
                                    </div>
                                    <br/>
                                    <Button icon={<PlusOutlined/>} iconPosition={"start"} type={"primary"}
                                            href={'/quickfiling/process/profile'}> Create
                                        profile </Button>
                                </Flex>}
                        </Sider>
                        <Content>
                            <div style={{margin: '30px', minHeight: '720px'}}><Outlet/></div>
                        </Content>
                    </Layout>
                </Layout>


                <FloatButton
                    onClick={onClickFloatButton}
                    icon={<CustomerServiceOutlined style={{fontSize: '20px'}}/>}
                    type="primary"
                    shape={"square"}
                    style={{
                        insetInlineEnd: 20,
                        bottom: 20,
                        width: 45,  // 调整宽度
                        height: 45, // 调整高度
                    }}
                />
            </div>
        </div>


    );
};
export default QuickFiling;

