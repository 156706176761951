import React, {useEffect, useState} from 'react';
import {getPaperListFromGoogleScholar} from "@/apis/googleApi";
import {Button, Flex, message, Spin, Table} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";



const columns = [
        {
            title: 'Year',
            dataIndex: 'year',
            width: 30,
            sorter: (a, b) => {
                const yearA = parseInt(a.year, 10);  // 确保 year 被转换为数字
                const yearB = parseInt(b.year, 10);
                return yearA - yearB;
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Title',
            dataIndex:
                'title',
            width:
                150
        }
        ,
        {
            title: 'Authors',
            dataIndex:
                'authors',
            ellipsis:
                true,
            width:
                120
        }
        ,
        {
            title: 'Cited By',
            dataIndex:
                ['cited_by', 'value'],
            width:
                40,
            sorter:
                (a, b) => {
                    const aValue = a.cited_by?.value || 0;
                    const bValue = b.cited_by?.value || 0;
                    return aValue - bValue;
                },
            sortDirections:
                ['descend', 'ascend']
        }
        ,
    ]
;

const ScholarPapersTable = ({research, handleSelect}) => {

    const [papers, setPapers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState('indexed'); // Set default active
    //const quickFormData = useSelector(state => state.quickForm.quickFilingForm);

    const getPaperList = (gs_link) => {

        const userMatch = gs_link.match(/[?&]user=([^&]+)/);
        let userValue;
        if (userMatch && userMatch[1]) {
            userValue = userMatch[1];
        } else {
            console.log("User parameter not found");
            message.error("please input correct scholar link");
        }
        if (userValue && userValue.length > 0) {
            setLoading(true);
            getPaperListFromGoogleScholar(userValue, 'articles').then(
                resp => {
                    if (resp.code === 0) {
                        console.log(resp);
                        setPapers(resp.data.map((paper) => ({...paper, key: paper.citation_id})));
                    }
                }
            ).catch(
                e => {
                    console.log(e);
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }
    }

    useEffect(() => {
        const scholarURL  = research.scholarURL
        if (typeof scholarURL == 'string' && scholarURL !== '') {
            getPaperList(scholarURL);
        }
    }, [])


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        handleSelect(newSelectedRowKeys);
    };


    const rowSelection = {
        onChange: onSelectChange,
        type: 'radio'
    };


    return (
        <div className={'paper-list'}>
            <div>
                <Title level={3}>Select Articles</Title>
            </div>
            <br/>
            <div>
                <Flex justify={"center"} align={"center"}
                      style={{height: 45, width: '400px', backgroundColor: '#e6e4e4', borderRadius: 10}}>
                    <div>
                        <Button
                            size={"large"}
                            type={activeButton === 'indexed' ? 'primary' : 'text'}
                            onClick={() => setActiveButton('indexed')}
                        >
                            Indexed on google Scholar
                        </Button>
                        <Button
                            size={"large"}
                            type={activeButton === 'manual' ? 'primary' : 'text'}
                            onClick={() => setActiveButton('manual')}
                        >
                            Manually added
                        </Button>
                    </div>
                </Flex>
            </div>
            <br/>
            <div>
                <Flex gap="middle" vertical>
                    <Spin
                        spinning={loading}
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />}
                    >

                        <Table
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={papers}
                            pagination={false}
                            scroll={{
                                y: 600,
                            }}
                        />

                    </Spin>
                </Flex>
            </div>
        </div>
    )
}

export default ScholarPapersTable


