import React, { useState, useRef } from 'react';
import { Drawer, Button } from 'antd';
import './index.scss';

const ResizableDrawer = () => {
    const [visible, setVisible] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(400); // 初始宽度
    const startX = useRef(0); // 记录鼠标开始拖动的位置
    const startWidth = useRef(0); // 记录开始拖动时的宽度

    const showDrawer = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    const handleMouseDown = (e) => {
        // 记录鼠标开始拖动的位置和当前抽屉的宽度
        startX.current = e.clientX;
        startWidth.current = drawerWidth;

        // 监听鼠标移动和释放事件
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        // 计算新的抽屉宽度
        const newWidth = startWidth.current - (startX.current - e.clientX);
        if (newWidth > 200 && newWidth < window.innerWidth - 100) {
            // 限制最小宽度200px，最大宽度为窗口宽度-100px
            setDrawerWidth(newWidth);
        }
    };

    const handleMouseUp = () => {
        // 鼠标松开时移除事件监听
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    return (
        <>
            <Button type="primary" onClick={showDrawer}>
                打开抽屉
            </Button>
            <Drawer
                title="可拖动大小的抽屉"
                placement="right"
                onClose={closeDrawer}
                open={visible}
                width={drawerWidth}
                bodyStyle={{ padding: 0 }}
                headerStyle={{ padding: '10px' }}
            >
                <div className="drawer-content">
                    <p>这里是抽屉的内容</p>
                </div>
                {/* 左侧拖动条 */}
                <div
                    className="resize-handle-left"
                    onMouseDown={handleMouseDown}
                />
            </Drawer>
        </>
    );
};

export default ResizableDrawer;
