import React, {useState, useEffect, useContext} from 'react';
import {Progress, Typography, Button, Flex, Space} from 'antd';
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import "./index.scss"




const {Title, Paragraph} = Typography;

const GenerateReport = () => {

    const [progress, setProgress] = useState(null);
    const [done, setDone] = useState(false);
    const {setMainCurrent} = useContext(MainStepContext);


    useEffect(() => {
        setMainCurrent(6)
        // 模拟轮询后端获取进度的逻辑
        const interval = setInterval(() => {
            // 模拟后端返回进度，每次增加10，直到100%
            setProgress((prevProgress) => {
                if (prevProgress === null) return 0;
                if (prevProgress >= 100) {
                    setMainCurrent(7)
                    setDone(true)
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 10;
            });
        }, 1000); // 每秒查询一次

        return () => clearInterval(interval);
    }, []);


    const handleRefresh = () => {
        //todo 手动更新
        console.log('Refresh clicked');
    };

    const viewReport = () => {
        //todo 跳转到下载文档页面
        console.log('viewReport clicked');
    }








    return (
        <div style={{ margin : 20}}>
                <Flex justify={"center"} align={'center'} style={{ height: '70vh'}} >
                    {!done ? (
                        <div style={{width: '70%'}}>
                            <div>
                                {progress === null ? (
                                    // 当没有具体进度时，显示左右移动动画
                                    <div className={'movingBarStyle'}>
                                        <div className={'barStyle'}></div>
                                    </div>
                                ) : (
                                    // 当有具体进度时，显示进度条
                                    <div style={{width: '100%', backgroundColor: '#e6e4e4', borderRadius: '5px'}}>
                                        <div className={'progressStyle'} style={{width: `${progress}%`}}></div>
                                    </div>
                                )}

                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <Flex justify={"center"} align={'center'} vertical={true}>
                                <Space direction={'vertical'} size={'large'} align={'center'}>
                                    <Paragraph><strong>Progress: {progress || 0}/100</strong></Paragraph>
                                    <Paragraph>这里需要文案</Paragraph>
                                    <Title level={2}>Generating xxx now</Title>
                                    <Paragraph>
                                        We're generating your Summary of Contribution now.
                                        This may take a few minutes.
                                    </Paragraph>
                                    <Button type="primary" onClick={handleRefresh}>
                                        Refresh
                                    </Button>
                                </Space>
                            </Flex>
                        </div>


                    ) : (

                        <Flex vertical={true} align={'center'}>
                            <Title level={2}>Your xxx is ready!</Title>
                            <br/>
                            <br/>
                            <br/>
                            <Button type="primary" onClick={viewReport}>View your report</Button>
                        </Flex>

                    )}
                </Flex>
        </div>
    );
};

export default GenerateReport;

