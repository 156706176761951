

// 自定义字符计数策略：将 emoji 计为 1 个字符
const emojiCountStrategy = (value) => {
    return Array.from(value).length;
};

// 自定义超出 max 的字符裁剪逻辑
const customExceedFormatter = (value, config) => {
    if (value.length > config.max) {
        return `${value.slice(0, config.max)}`; // 裁剪并加上省略号
    }
    return value;
};



const isEmptyObj = (obj) => {
    return obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);
};

const isEmptyArray = (arr) => {
    return arr == null || (Array.isArray(arr) && arr.length === 0);
};


export {isEmptyObj, isEmptyArray, customExceedFormatter, emojiCountStrategy };


export const removeCurrentFormItem = (stepKey, index) => {

    // 从 localStorage 获取已保存的表单数据
    const savedData = JSON.parse(localStorage.getItem('quickfiling-formData')) || {};
    // 检查是否存在 stepKey 并确保其为数组类型
    if (Array.isArray(savedData[stepKey])) {
        // 移除 localStorage 中对应的项
        const updatedData = savedData[stepKey].filter((_, i) => i !== index);
        // 如果更新后的数组为空，则删除该 key，否则更新该 key
        if (updatedData.length > 0) {
            savedData[stepKey] = updatedData;
        } else {
            delete savedData[stepKey]; // 删除空的 stepKey
        }
        // 更新 localStorage
        localStorage.setItem('quickfiling-formData', JSON.stringify(savedData));
    }
}