import React, {useContext, useEffect, useState} from 'react';
import {Button, Flex, Modal, Space, Table, Tag,  Typography} from "antd";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import ReviewHighlightsTable from "@/components/quickfilingComp/tables/reviewHighlightsTable";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";


const {Paragraph, Title} = Typography;





const data = {
    111 : [
        {
            key : 1,
            title: 'High loading of single atomic iron sites in Fe–NC oxygen reduction catalysts for proton exchange membrane fuel cells',
            publication: 'A Mehmood, M Gong, F Jaouen, A Roy, A Zitolo… - Nature Catalysis, 2022 - nature.com',
            year: '2022',
            citations: 200,
            highlights: 200,
            selected: {
                positive: 5,
                neutral: 5,
            }
        },
        {
            key : 2,
            title: 'High loading of single atomic iron sites in Fe–NC oxygen reduction catalysts for proton exchange membrane fuel cells',
            publication: 'A Mehmood, M Gong, F Jaouen, A Roy, A Zitolo… - Nature Catalysis, 2022 - nature.com',
            year: '2022',
            citations: 200,
            highlights: 200,
            selected: {
                positive: 5,
                neutral: 5,
            }
        },
    ],

    222 : [
        {
            key : 1,
            title: 'High loading of single atomic iron sites in Fe–NC oxygen reduction catalysts for proton exchange membrane fuel cells',
            publication: 'A Mehmood, M Gong, F Jaouen, A Roy, A Zitolo… - Nature Catalysis, 2022 - nature.com',
            year: '2022',
            citations: 200,
            highlights: 200,
            selected: {
                positive: 5,
                neutral: 5,
            }
        },
        {
            key : 2,
            title: 'High loading of single atomic iron sites in Fe–NC oxygen reduction catalysts for proton exchange membrane fuel cells',
            publication: 'A Mehmood, M Gong, F Jaouen, A Roy, A Zitolo… - Nature Catalysis, 2022 - nature.com',
            year: '2022',
            citations: 200,
            highlights: 200,
            selected: {
                positive: 5,
                neutral: 5,
            }
        },
    ]
}


const ReviewCitation = () => {

    const {setMainCurrent} = useContext(MainStepContext);
    const [genData, setGenData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            ellipsis: true,
            width: '30px'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Publication',
            dataIndex: 'publication',
            key: 'publication',
            ellipsis: true,
            width: '150px'
        },
        {
            title: 'Citations',
            dataIndex: 'citations',
            key: 'citations',
            ellipsis: true,
            width: '40px'
        },
        {
            title: 'Selected',
            dataIndex: 'selected',
            key: 'selected',
            render: (_, record) => {
                const {positive, neutral} = record.selected;
                return (
                    <Space>
                        <Tag color="#87d068">Positive({positive})</Tag>
                        <Tag color="#3b5999">Neutral({neutral})</Tag>
                    </Space>

                )
            },
            width: '120px'
        },

        {
            title: 'Highlights',
            dataIndex: 'highlights',
            key: 'highlights',
            ellipsis: true,
            width: '40px'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button type={"primary"} onClick={showModal}>Review</Button>
            ),
            width: 50
        },
    ];

    // 显示 Modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    // 关闭 Modal
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        setMainCurrent(3)
        setGenData(data)
    }, [])


    const generateEndeavor = () => {
        navigate('/quickfiling/process/endeavor')
    }

    return (
        <div className={'review-citation'}>
            <div>
                <Flex justify={'space-between'} align={'center'}>

                        <div>
                            <Title level={4}>Review Highlights</Title>
                            <Typography.Paragraph>Review citation highlights and select the ones you want to
                                include</Typography.Paragraph>
                        </div>
                    <Space>
                        <Button size={"large"} danger>Regenerate</Button>
                        <Button size={"large"} type={"default"}>Refresh</Button>
                        <Button size={"large"} type={"primary"} onClick={generateEndeavor}>Generate proposed endeavor</Button>
                    </Space>
                </Flex>
            </div>


            <br/>
            <br/>

            {genData != null && Object.keys(genData).map((category, index) => (

                <div key={index}>
                    <Space align={"baseline"}>
                        <Title level={2}>Category:  {category}</Title>
                        <InfoCircleOutlined />
                        <Paragraph>ect the ones you want to</Paragraph>
                    </Space>
                    <Table columns={columns}
                           showHeader={true}
                           dataSource={genData[category]}
                           pagination={false}
                           scroll={{
                               y: 400,
                           }}
                    />
                    <br/>
                </div>
            ))}


            <Modal
                open={isModalVisible}
                onCancel={handleCancel}
                centered={true}
                closable={false}
                footer={null} // 如果不需要 footer，可以设置为 null
                width={'100rem'}  // 自定义 modal 宽度

            >

                <ReviewHighlightsTable/>

            </Modal>

        </div>
    )
}


export default ReviewCitation;