import React, {useEffect} from 'react';
import {Button, Space, Table} from 'antd';
import Title from "antd/es/typography/Title";


const columns = [
    {
        title: 'Your publication',
        dataIndex: 'title',
        key: 'title',
        render: (text) => <a>{text} </a>,
        ellipsis: true,
        width: '300px'
    },
    {
        title: 'Citation',
        dataIndex: 'citation',
        key: 'citation',
        render: (text) => <a>{text} </a>,
        ellipsis: true,
        width: '300px'
    },
    {
        title: 'Resource Link',
        dataIndex: 'link_url',
        key: 'link_url',
        render: (text) => <a>{text} </a>,
        ellipsis: true,
        width: '200px'
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
            <Space size="middle">
                <Button danger >Ignore</Button>
                <Button type={"primary"} >Update</Button>
            </Space>
        ),
        width : 150
    },
];



const data = [
    {
        key: '1',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '2',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '3',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
    {
        key: '4',
        title: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        citation: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
        link_url: 'Chemical vapour deposition of Fe–N–C oxygen reduction catalysts with full utilization of dense Fe–N4 sites',
    },
];



const CheckMissingFileTable = () => {

    const [missingData, setMissingData] = React.useState([]);


    useEffect( ()=>{
        //todo 从服务端获取missFile
        setTimeout(()=>{
            setMissingData(data);
        }, 1000)
    }, [])



    return (

        <div>
            <Title level={4}>Upload missing citation({missingData.length})</Title>
            <Table columns={columns}
                   showHeader={true}
                   dataSource={missingData}
                   pagination={false}
                   scroll={{
                       y: 400,
                   }}
            />
        </div>
    )
}
export default CheckMissingFileTable;