
import {request} from "@/utils/request";


export async function askChatGPTApi(data) {
    return request({
        url: "/chat",
        method: "POST",
        data: data,
        timeout: 10000,
    })
}


