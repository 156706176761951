import {request} from "@/utils";


export async function getPaperListFromGoogleScholar(author_Id, content) {
    return request({
        url: "/quickfiling/getScholarAuthorInfo",
        method: "GET",
        params: {
            author_Id: author_Id,
            content: content
        }
    })
}