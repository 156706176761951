import React, {useContext, useEffect, useState} from 'react';
import {Button, Flex, Space, Switch} from "antd";
import Title from "antd/es/typography/Title";
import {InfoCircleOutlined} from "@ant-design/icons";
import CheckMissingFileTable from "@/components/quickfilingComp/tables/checkMissingFileTable";
import CheckMissingFiles from "@/components/quickfilingComp/tables/verifyExistFiles";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import {useNavigate} from "react-router-dom";

const CheckMissFile = () => {


    // 使用 useState 钩子来维护当前激活的标签状态
    const [activeTab, setActiveTab] = useState('checkFiles');
    const [checked, setChecked] = useState(false);
    const { setMainCurrent } = useContext(MainStepContext);
    const navigate = useNavigate();

    const handleToggle = () => {
        setChecked(!checked);  // 切换开关状态
    };


    useEffect( () => {
        setMainCurrent(2);
    }, [])


    // 定义不同状态下渲染的内容
    const renderContent = () => {
        switch (activeTab) {
            case 'checkFiles':
                return <div><CheckMissingFileTable /></div>;
            case 'verifyFiles':
                return <div><CheckMissingFiles /></div>;
            case 'uploadCitations':
                return <div> todo --- Upload Missing Citations Content</div>;
            default:
                return null;
        }
    };

    const generateHighlights = () => {
        navigate('/quickfiling/process/review')
    }

    return (

        <div>
            <div className="check-missing-container">
                <div className={'title'}>
                    <Flex justify={'space-between'} align={'center'}>
                        <Space align={'baseline'}>
                            <span><InfoCircleOutlined /></span>
                            <Title level={5}> Add any missing publication or citation of your papers. Ignore the ones which you don't want to include in the petition letter. </Title>
                        </Space>
                        <Button type={"primary"} onClick={generateHighlights}>Next: Generate Citation Highlights</Button>
                    </Flex>
                     </div>
                <Flex justify={'space-between'} align={'center'}>
                    <Flex justify={"center"} align={"center"}
                          style={{padding: '0 5px', height: 45, backgroundColor: '#e6e4e4', borderRadius: 10}}>
                        <Button
                            type={activeTab === 'checkFiles' ? 'primary' : 'text'}
                            onClick={() => setActiveTab('checkFiles')}
                        >
                            Check Missing Files
                        </Button>
                        <Button
                            type={activeTab === 'verifyFiles' ? 'primary' : 'text'}
                            onClick={() => setActiveTab('verifyFiles')}
                        >
                            Verify Existing Files
                        </Button>
                        <Button
                            type={activeTab === 'uploadCitations' ? 'primary' : 'text'}
                            onClick={() => setActiveTab('uploadCitations')}
                        >
                            Upload Missing Citations
                        </Button>
                    </Flex>

                    <div>
                        <Space align={'center'} style={{cursor: 'pointer'}}>
                            <Switch checked={checked} onChange={handleToggle}/>
                            <span onClick={handleToggle} style={{userSelect: 'none'}}>Show Ignored Files</span>
                        </Space>
                    </div>
                </Flex>

                <br />

                {/* 根据状态渲染内容 */}
                <div style={{marginTop: '20px'}}>
                    {renderContent()}
                </div>
            </div>
        </div>
    )

}


export default CheckMissFile