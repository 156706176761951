import {Avatar, Button, Card, Flex, Typography} from "antd";
import {default_avatar_png} from "@/data/data";
import {CaretRightOutlined, MoreOutlined} from "@ant-design/icons";
import TimeDisplay from "src/components/communityComp/timeDisplay";
import WriteComment from "@/components/communityComp/comment/writeComment";
import React, {useState} from "react";
import "./index.scss"
import {getCommentPageApi} from "@/apis/question";



const {Meta} = Card;
const {Paragraph} = Typography;


const CommentItem = ({qid, item, updateCommentItem}) => {

    const [expandReply, setExpandReply] = useState(false);

    // todo
    //const [liked, setLiked] = useState(false);
    //对当前评论是否点过赞？
    //评论点赞数
    //useEffect(()=>{}, [])

    const updateCommentItemAndCloseWriteComment = (newComment) => {
        setExpandReply(false)
        updateCommentItem(newComment)
    }

    return (
        <div style={{marginTop: 4}}>
            <Flex gap={10} align="start">
                <Avatar shape={"square"} src={item?.user_avatar || default_avatar_png}/>
                <div className="commentItem" style={{width: "100%"}}>
                    <div style={{background: "#f2f2f2", padding: 7, borderRadius: '15px 15px 15px 15px'}}>
                        <Meta style={{marginBottom: '5px'}}
                              title={
                                  <Flex justify={'space-between'} align={'center'}>
                                      <div>
                                          {item?.username || "unDefine"}
                                          {item?.reply_username !== "" &&
                                              <span><CaretRightOutlined/> {item?.reply_username}</span>}
                                      </div>
                                      <TimeDisplay timestamp={item?.created_at}/>
                                  </Flex>
                              }
                        />
                        <Paragraph>
                            {item?.original_text}
                        </Paragraph>
                    </div>
                    <div style={{marginTop: 4}}>
                        <Button size={"small"} type={"text"} >Like</Button> <MoreOutlined/>
                        <Button size={"small"} type={"text"}
                                onClick={() => {
                                    setExpandReply(!expandReply)
                                }}>Reply</Button>
                    </div>
                    {expandReply && <WriteComment qid={qid} item={item} updateCommentItem={updateCommentItemAndCloseWriteComment}/>}
                </div>
            </Flex>
        </div>
    )
}


const EachComment = ({qid, item}) => {

    const [children, setChildren] = useState(item.replies || []);
    const [page, setPage] = useState(2);


    const updateCommentItem =(newCommentItem) => {
        setChildren(prev => [...prev, newCommentItem])
    }

    const loadMoreChildren = () => {
        setPage(prev => prev + 1)
        getCommentPageApi(qid, page, 3, item.comment_id).then(response => {
            if (response.code === 0) {
                setChildren(prev => [...prev, ...response.data.comments]);
            } else {
                throw new Error('get Comment failed')
            }
        }).catch(
            (err) => {
                console.log(err);
            }
        )
    }

    return (
        <div>
            <CommentItem qid={qid} item={item} updateCommentItem={updateCommentItem}/>
            <div style={{marginLeft: 40}}>
                {children.map(c => (
                    <CommentItem key={c.comment_id} qid={qid} item={c} updateCommentItem={updateCommentItem}/>
                ))}
            </div>
            {item.total_replies > children.length &&
                <Flex justify={'end'}>
                    <Button type={'text'} onClick={loadMoreChildren} >Load more replies</Button>
                </Flex>
            }
        </div>
    )

}


export default EachComment;

