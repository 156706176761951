// 封装基于ls存取删三个方法

const TOKEN_KEY = 'access_token'
const QUICK_FROM = 'quickfiling-formData'

function localStorageSetToken (token) {
    return localStorage.setItem(TOKEN_KEY, token)
}

function localStorageGetToken () {
    return localStorage.getItem(TOKEN_KEY)
}

function localStorageRemoveToken () {
    return localStorage.removeItem(TOKEN_KEY)
}

function localStorageRemoveQuickFilingForm() {
    return localStorage.removeItem(QUICK_FROM)
}

function localStorageSetQuickFilingForm(data) {
    return localStorage.setItem(QUICK_FROM, data)
}

function localStorageGetQuickFilingForm() {
    return localStorage.getItem(QUICK_FROM)
}



export {
    localStorageSetToken,
    localStorageGetToken,
    localStorageRemoveToken,
    localStorageRemoveQuickFilingForm,
    localStorageGetQuickFilingForm,
    localStorageSetQuickFilingForm
}