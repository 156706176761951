import React , {useState} from 'react';
import {Button, Typography} from "antd";


const {Paragraph} = Typography;


// TextMore component from your example
export const TextMore = ({ text , rows = 3 }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            <Paragraph
                ellipsis={visible ? false : { rows: rows, expandable: true, symbol: <Button size={"small"} type={"text"} style={{ visibility: 'hidden' }}>More</Button> }}
            >
                {text}
                {visible && <Button danger type={"text"} size={"small"}  onClick={() => setVisible(false)} >Less</Button>}
            </Paragraph>
            {!visible && (
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <Button danger type={"text"} size={"small"} onClick={() => setVisible(true)}>More</Button>
                </div>
            )}
        </div>
    )
};
