import {Button, Card, Flex, Input, Space, Tag, Tooltip, Typography} from "antd";
import React, {useState, useEffect, useContext} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {LinkOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";


const {Title, Paragraph} = Typography;


const initCol = [
    {
        id: 'uncategorized',
        title: 'Uncategorized',
        achievements: [],
        description: "Uncategorized items will be ignored in the Summary of Contribution",
    },
    {
        id: '111', title: '111', achievements: [], description: "2222",
    },
];


export const reOrderArticles = ({columns, source, destination}) => {
    // 查找源列和目标列
    const sourceColumnIndex = columns.findIndex(col => col.id === source.droppableId);
    const destColumnIndex = columns.findIndex(col => col.id === destination.droppableId);

    const sourceColumn = columns[sourceColumnIndex];
    const destColumn = columns[destColumnIndex];

    const sourceArticles = Array.isArray(sourceColumn.achievements) ? [...sourceColumn.achievements] : [];
    const destArticles = Array.isArray(destColumn.achievements) ? [...destColumn.achievements] : [];

    if (sourceColumnIndex === destColumnIndex) {
        // 如果在同一列中拖动
        const [movedItem] = sourceArticles.splice(source.index, 1);
        sourceArticles.splice(destination.index, 0, movedItem);

        // 更新当前列的文章
        const updatedColumn = {
            ...sourceColumn,
            achievements: sourceArticles,
        };

        // 返回更新后的列
        return columns.map((col, idx) =>
            idx === sourceColumnIndex ? updatedColumn : col
        );
    } else {
        // 跨列拖动
        const [movedItem] = sourceArticles.splice(source.index, 1);
        destArticles.splice(destination.index, 0, movedItem);

        // 更新源列和目标列的文章
        const updatedSourceColumn = {
            ...sourceColumn,
            achievements: sourceArticles,
        };

        const updatedDestColumn = {
            ...destColumn,
            achievements: destArticles,
        };

        // 返回更新后的列数组
        return columns.map((col, idx) => {
            if (idx === sourceColumnIndex) return updatedSourceColumn;
            if (idx === destColumnIndex) return updatedDestColumn;
            return col;
        });
    }
};

// 模拟一个异步获取列表数据的函数
const fetchListFromServer = async () => {
    return [
        {
            id: 'articles-1',
            type: 'article',
            link_url: '#',
            title: 'Experimental Sabatier plot for predictive design of active and stable Pt-alloy oxygen reduction reaction catalysts'
        },
        {
            id: 'articles-2',
            type: 'patent',
            link_url: '#',
            title: 'Experimental Sabatier plot for predictive design of active and stable Pt-alloy oxygen reduction reaction catalysts'
        },
        {
            id: 'articles-3',
            type: 'pre',
            link_url: '#',
            title: 'Experimental Sabatier plot for predictive design of active and stable Pt-alloy oxygen reduction reaction catalysts'
        }];
};


const CategoryColumn = (props) => {

    const column = props.column;

    return (
        <Droppable droppableId={column.id} key={column.id}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        backgroundColor: snapshot.isDraggingOver ? 'lightblue' : '#ebeff7',
                        width: '220px',
                        padding: '10px 0',
                        borderRadius: '10px',
                        minHeight: '700px'
                    }}
                >
                    {column.achievements.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        margin: '10px 5px',
                                        ...provided.draggableProps.style,
                                    }}
                                >
                                    <Tag color={item.type === 'article' ? '#2db7f5' : item.type === 'patent' ? '#f50' : '#896410'}>
                                        {item.type}
                                    </Tag>
                                    <div><Link to={item.link_url}>{item.title} <LinkOutlined/></Link></div>
                                </Card>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder} {/* 确保提供 placeholder */}
                </div>
            )}
        </Droppable>
    );
};

const OrganizeResearch = () => {

    const [columns, setColumns] = useState(initCol);
    const [newColumnTitle, setNewColumnTitle] = useState('');
    const [newColumnDescription, setNewColumnDescription] = useState('');
    const [resetColumns, setResetColumns] = useState(initCol);  // 保存初始状态
    const nav = useNavigate()
    const { setMainCurrent } = useContext(MainStepContext);


    const handleReset = () => {
        setColumns(resetColumns);  // 恢复初始状态
    };


    // 在组件加载时从服务器获取数据，并初始化第一列
    useEffect(() => {
        const fetchData = async () => {
            const serverList = await fetchListFromServer();

            // 通过列的 id 找到要更新的列，并修改其 articles
            const updatedColumns = columns.map(col => {
                if (col.id === 'uncategorized') {
                    return {...col, achievements: serverList};
                }
                return col;
            });

            setColumns(updatedColumns); // 更新 columns 数组
            setResetColumns(updatedColumns);
        };
        fetchData();
        setMainCurrent(1);
    }, []);



    const handleSave = () => {
        const filteredColumns = columns.filter(col => col.title !== 'Uncategorized');  // 过滤掉 Uncategorized
        console.log('Saved Data:', filteredColumns);

        //todo 保存到服务端
    };


    const handleConfirm = () => {
        handleSave()
        //todo 跳转到下一步
        nav('/quickfiling/process/checkMissing')
    };


    const handleDeleteColumn = (columnId) => {
        const newColumns = columns.filter(col => col.id !== columnId);  // 使用 filter 删除列
        setColumns(newColumns);
    };

    const handleAddColumn = () => {
        const newColumnId = `column-${Date.now()}`;
        const newColumn = {
            id: newColumnId,
            title: newColumnTitle,
            description: newColumnDescription,
            achievements: [],
        };

        setColumns([...columns, newColumn]);  // 使用数组的扩展运算符添加新列
        setNewColumnTitle('');  // 清空输入框
        setNewColumnDescription('');
    };


// 处理标题和描述的编辑
    const handleTitleChange = (id, newTitle) => {
        setColumns(columns.map(col =>
            col.id === id ? {...col, title: newTitle} : col
        ));
    };

    const handleDescriptionChange = (id, newDescription) => {
        setColumns(columns.map(col =>
            col.id === id ? {...col, description: newDescription} : col
        ));
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;

        // 检查是否有效拖动
        if (!destination) return;

        // 只处理文章的拖动
        const data = reOrderArticles({columns, source, destination});
        setColumns(data);
    };


    return (
        <div className={"organize-research"}>
            <div>
                <Flex justify={'space-between'}>
                    <Title level={4}>
                        Group your research by topic
                    </Title>
                    <Space>
                        <Button danger onClick={handleReset}>Reset</Button>
                        <Button onClick={handleSave}>Save</Button>
                        <Button type={"primary"} onClick={handleConfirm}>Confirm</Button>
                    </Space>
                </Flex>
            </div>

            <Space size={"large"} align={"start"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Space size={'large'} align={"start"}>
                        {columns.map((item, index) => (
                            <div key={item.key} className={'category-container'}>
                                <div className={'title'}>
                                    <Flex align={'center'} vertical={true}>
                                        <Tooltip title={item.title}>
                                            <Title level={4}
                                                   ellipsis={{rows: 1}}
                                                   editable={item.title !== 'Uncategorized' ? {onChange: (newTitle) => handleTitleChange(item.id, newTitle)} : false}
                                                   style={{width: '100%', maxWidth: '200px', textAlign: 'center'}}
                                            >{item.title}</Title>
                                        </Tooltip>
                                        <Tooltip title={item.description}>
                                            <Paragraph ellipsis={{rows: 1}}
                                                       editable={item.title !== 'Uncategorized' ? {onChange: (newDescription) => handleDescriptionChange(item.id, newDescription)} : false}
                                                       style={{width: '100%', maxWidth: '200px', textAlign: 'center'}}>
                                                {item.description}
                                            </Paragraph>
                                        </Tooltip>
                                    </Flex>
                                </div>
                                <Flex justify={'space-evenly'}>
                                    <Button type={"text"} danger
                                            onClick={() => handleDeleteColumn(item.id)}>delete</Button>
                                </Flex>
                                <CategoryColumn
                                    index={index}
                                    column={item}
                                />
                            </div>))}
                    </Space>
                </DragDropContext>

                {/* 添加新列的输入框和按钮 */}
                <div className={'category-container'}>
                    <Flex vertical={true} align={'center'}>
                        <Title level={4}>Add Column</Title>
                        <Input
                            size={'large'}
                            type="text"
                            placeholder="New column title"
                            value={newColumnTitle}
                            onChange={(e) => setNewColumnTitle(e.target.value)}
                        />
                        <br/>
                        <Input.TextArea
                            placeholder="Column description"
                            value={newColumnDescription}
                            onChange={(e) => setNewColumnDescription(e.target.value)}
                        />
                        <br/>
                        <Button onClick={handleAddColumn} type={"primary"}>Add</Button>
                    </Flex>

                </div>
            </Space>
        </div>
    )
}

export default OrganizeResearch